import aes from "crypto-js/aes"
import utf8 from "crypto-js/enc-utf8"
import config from "../config/app";
import moment from "moment";

const { currencySymbol } = config;



export const getActiveItems = (items: any) => {
  if (items.length === 0) {
    return [];
  }
  return items.filter((item: any) => item.isActive);
};


export const isValidDate = (date: any) => {
  // Use moment's strict parsing mode to ensure the format is exactly as expected
  return moment(date, "DD-MM-yyyy", true).isValid();
};

export const thousandSeperator = (value: any) => {
  if (typeof value === "string") {
    // Check if the string starts with "R" followed by a number
    const match = /^R([\d.]+)$/.exec(value);
    if (match) {
      // Remove existing commas
      const numberValue = parseFloat(match[1].replace(/,/g, ""));
      // Add comma separator and preserve 2 decimal places
      return (
        "R" +
        numberValue.toLocaleString(undefined, { minimumFractionDigits: 2 })
      );
    }
  }
  // Return the input as is if it doesn't match the expected format
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const generateNonce = () => {
  const nonceCharacters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let nonce = "";

  // Generate a random nonce of a specific length (e.g., 32 characters)
  for (let i = 0; i < 32; i++) {
    nonce += nonceCharacters.charAt(
      Math.floor(Math.random() * nonceCharacters.length)
    );
  }

  return nonce;
};


export const setUser = (user: any) => {
  localStorage.setItem("userAuth", encrypt(JSON.stringify(user)));
};

export const removeUser = () => {
  localStorage.removeItem("userAuth");
};

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const setLocal = (localName: any, data: any) => {
  return localStorage.setItem(localName, data);
};
export const removeLocal = (localName: any) => {
  return localStorage.removeItem(localName);
};
export const getLocal = (localName: any) => {
  return localStorage.getItem(localName);
};

export const setCookie = (key: string, data: string, expirationDate: number | Date) => {
  let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(data)}`;

  if (expirationDate) {
    let expiryDate;
    if (typeof expirationDate === 'number') {
      expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + expirationDate);
    } else if (expirationDate instanceof Date) {
      expiryDate = expirationDate;
    } else {
      // Handle invalid expirationDate type if needed, for now assume it's number or Date
      console.warn("Invalid expirationDate type. Expected number or Date.");
      return false; // Or handle as session cookie, or throw error. For now return false to indicate issue.
    }
    cookieString += `;expires=${expiryDate.toUTCString()}`;
  }

  cookieString += `;SameSite=Strict`;
  document.cookie = cookieString;
  return true;
};


export const getCookie = (name: any) => {
  const cookies = document.cookie.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const cookieParts = cookie.split("=");
    const cookieName = cookieParts[0];
    const cookieValue = decodeURIComponent(cookieParts[1]);

    if (cookieName === name) {
      return cookieValue;
    }
  }

  return null;
};

export const encrypt = (value: any) => {
  return aes.encrypt(
    value,
    "FHRVr+XvNJh@3N$$wV_m7ujVp2#G5n2f"
  ).toString();
};

export const decrypt = (text: any) => {
  if (!text) {
    return null;
  }
  const bytes = aes.decrypt(text, "FHRVr+XvNJh@3N$$wV_m7ujVp2#G5n2f");
  return bytes.toString(utf8);
};

export const getUnixTimeStamp = () => {
  return Math.round(new Date().getTime() / 1000);
};

export const dateFormat = (date: any) => {
  return moment(date).format("DD-MM-YYYY");
};

export const dobFormat = (date: any) => {
  return moment(date).format("DD-MM-YYYY");
};

export const dateDefaultFormat = (date: any) => {
  return moment(date, "YYYY-MM-DD").format("dddd D MMMM YYYY");
};

export const timeFormat = (time: any) => {
  return moment(time).format("HH:MM");
};

export const formatCurrency = (value: any) => {
  const digits = value.replace(/\D/g, "");
  if (value === 0) {
    return "0.00";
  }
  return (parseFloat(digits) / 100).toFixed(2).toString();
};

export const getLatLong = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  // Radius of the earth in km
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //distance in km
  return R * c;
};

const deg2rad = (deg: any) => {
  return deg * (Math.PI / 180);
};

export const removeCurrencySymbol = (number: any) => {
  if (number) {
    return number.replace(/R/g, "");
  }
};

export const setNumberWithCurrency = (number: any) => {
  let num = 0;
  if (number) {
    num = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return num;
};

export const setNumberWithCurrency1 = (number: any) => {
  let num = "";
  if (number) {
    num = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return num;
};

