import { createContext, useContext, useEffect, useRef, useState } from "react"
import { InputState, MenuItemPrice } from "../../SuperRenderer/sub/models"
import { CartItem } from "../../SuperRenderer/sub/models/cart"

export const CART_KEY = "__cart_data__"

export interface CartState {
    items: Record<string, CartItem>
    deploymentId?: string
    orderNotes: string
    deliveryAddressOid?: string;

}






export const CartContext = createContext<CartHandle | null>(null)

export const useCart = () => useContext(CartContext) as CartHandle;






export interface Cache {
    variantsName: SelectionEntry[]
    optionsName: SelectionEntry[]
}


export interface SelectionEntry {
    title: string
    selection?: string
    price?: string
}


export const useCartState = () => {
    const [state, setState] = useState<CartState>({ items: {}, orderNotes: "" })
    const [allTotal, setAllTotal] = useState<number>(0)


    useEffect(() => {

        if (!state || !state.items) {
            return
        }

        let nextAllTotal = 0
        Object.values(state.items).forEach((item) => {
            nextAllTotal = nextAllTotal + itemTotal(item)
        })

        setAllTotal(nextAllTotal)

    }, [state])

    const itemTotal = (item: CartItem) => {

        let nextAllTotal = 0
        Object.values(item.subMenuItemPrices).forEach((subMenuItem) => {
            nextAllTotal = nextAllTotal + subMenuItem.total
        })

        nextAllTotal = (nextAllTotal + ((item.itemPrice + item.extraPrice) )) * item.count

        return nextAllTotal
    }


    const read = useRef(false)

    useEffect(() => {
        if (window === undefined || window.localStorage === undefined) {
            console.log("@skipping/itserver")
            return
        }
        if (read.current) {
            console.log("@skipping/alreadyread")
            return
        }

        const cartdata = localStorage.getItem(CART_KEY)
        if (cartdata) {
            try {
                setState(JSON.parse(cartdata))
                console.log("@setState")
            } catch (error) {
                console.error("@clearing/cartstate", error)
                clearItems()
            }
        }

        console.log("@cart/state/readcomplete", cartdata, JSON.stringify(state))

        read.current = true


    }, [])

    const mutated = useRef(false)
    useEffect(() => {
        if (mutated.current) {
            saveData()
        }

    }, [state])



    const clearItems = (deploymentId?: string) => {
        mutated.current = true
        console.error("@cartstate/clearItems")
        setState({ deploymentId, items: {}, orderNotes: "" })
        localStorage.removeItem(CART_KEY)
    }


    const increaseCount = (itemId: string) => {

        mutated.current = true

        setState((old) => {
            const oldItem = state.items[itemId]
            if (!oldItem) {
                return old
            }



            oldItem.count = oldItem.count + 1



            return { ...old }
        })


    }


    const decreaseCount = (itemId: string) => {
        mutated.current = true

        setState((old) => {
            const oldItem = state.items[itemId]
            if (!oldItem) {
                return old
            }

            if (oldItem.count === 1) {
                delete old.items[itemId]
            } else {
                oldItem.count = oldItem.count - 1
            }



            return { ...old }
        })


    }


    const setItem = (item: CartItem) => {
        mutated.current = true
        setState((old) => ({ ...old, items: { ...old.items, [item.cartItemId]: item } }))

    }

    const removeItem = (id: string) => {
        mutated.current = true

        setState((old) => {
            delete old.items[id]
            return { ...old }
        })

    }

    const saveData = () => {
        console.log("@saving", state)

        localStorage.setItem(CART_KEY, JSON.stringify(state))


    }

    const setOrderNotes = (notes: string) => {
        setState((old) => ({ ...old, orderNotes: notes }))
    }

    const setAddressOid = (addrId?: string) => {
        setState({ ...state, deliveryAddressOid: addrId });
      }
      

    return { 
        setAddressOid,
        setOrderNotes, 
        setItem, 
        removeItem, 
        clearItems, 
        saveData, 
        decreaseCount, 
        increaseCount, 
        itemTotal, 
        state, 
        allTotal 
    }
}


export type CartHandle = ReturnType<typeof useCartState>

