import { GlobalApiCache, GlobalCache } from "./gac";

export const gcache: GlobalCache = new  GlobalApiCache()



export let cache: CacheType | null = null

interface CacheType {
    deploymentUuid?: string,
    menuUuid?: string,
    rendererId?: string
    serviceType?: string
    initialInputs?: Record<string, any>
    subMenuItemPrices: Record<string, any>
    count: number
}


export const setValue = (data: CacheType) => {
    cache = data

}

// THEY BOTH ARE DIFFERENT KIND OF CACHE