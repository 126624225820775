import * as api from "../../../../state/api"
import { cache, gcache } from "./cache";

export const loader = {
    LoadMenu: async (mid: string, did: string, itemid: string) => {
        const item = gcache.getMenuItem(did, itemid)
        if (item) {
            return item
        }

        const resp = await api.getMenuItem(did, itemid)
        if (resp?.status !== 200) {
            throw resp?.data
        }

        gcache.setMenuItem(resp?.data)

        return resp?.data
    },

    LoadVariant: async (mid: string, did: string, itemid: string, vid: string) => {
        const item = gcache.getVariant(did, itemid, vid)
        if (item) {
            return item
        }


        const resp = await api.getMenuVariantByParentId(did, itemid, vid)
        if (resp?.status !== 200) {
            throw resp?.data
        }

        gcache.setVariant(resp?.data)

        return resp?.data
    }
}
