import { DataWrapper, httpClient } from "../http"



export interface LoginData {
    email: string;
    password: string;
    deviceToken: string
}


export interface LoginResponse {
    uuid: string
    mode: "logged" | "capture-phone" | "already-user-capture-phone" | "otp-password-capture"
    email: string
    cellphone: string
    firstName: string
    lastName: string
    accessToken?: string
    refreshToken?: string
    preauthToken?: string
    resetToken?: string
    deviceToken: string

}

export const userLogin = async (data: LoginData) => {
    return httpClient.post<DataWrapper<LoginResponse>>("/customer/auth/login", data)
}


export interface SignupData {
    ipaddr: string
    userName: string
    firstName: string
    lastName: string
    email: string
    cellphone: string
    password: string
    password_confirm: string
    smsOffers: boolean
    emailOffers: boolean
    dietary_check: string[]
    dateOfBirth: string
    countryCode: string
    country: string
}


export interface SetNewPassword {
    token: string, 
    newPassword: string
}

export const userSignup = async (data: SignupData) => {
    return httpClient.post<DataWrapper<LoginResponse>>("/customer/auth/signup", data)
}

export const userForgotPassword = async (email: string) => {
    return httpClient.post<DataWrapper>("/customer/auth/forgotPassword", { email })
}

export const userResetPasswordWithPhone = async (data: SetNewPassword) => {
    return httpClient.post<DataWrapper>("/customer/auth/resetPassword", data)
}

export const userResetPasswordWithEmail = async (data: SetNewPassword) => {
    return httpClient.post<DataWrapper>("/customer/auth/resetPassword/email", data)
}

export interface DevicePayload {
    token: string;
    platform: string;
    deviceModel: string;
    osVersion: string;
    appVersion: string;
    manufacturer: string;
}

export type setDeviceTokenPayload = DevicePayload

export const usersetDeviceToken = async (data: setDeviceTokenPayload) => {
    return httpClient.post("/customer/auth/setDeviceToken", data)
}


export const registerAnonDevice = async (data: DevicePayload) => {
    return httpClient.post("/customer/auth/registerAnonDevice", data)
}

export const refreshDeviceToken = async (data: { oldToken: string, newToken: string }) => {
    return httpClient.post("/customer/auth/refreshDeviceToken", data)
}


export const userSendOTP = async (preauthedTok: string) => {
    return httpClient.post<DataWrapper>("/customer/auth/sendOTP", {
        pre_auth_token: preauthedTok
    })
}


export interface OTPVerificationData {
    otp: string
    cellphone: string
    ipaddr: string
    pre_auth_token: string
    deviceToken: string
}

export const userVerifiOTP = async (data: OTPVerificationData) => {
    return httpClient.post<DataWrapper<LoginResponse>>("/customer/auth/OTPVerification", data)
}

export const userResetPasswordOTPVerification = async (data: any) => {
    return httpClient.post<DataWrapper<{
        token: string
        mode: "capture-pass" 
    }>>("/customer/auth/resetPasswordOTPVerification", data)
}

export const userPasswordResetQuery = async (data: any) => {
    return httpClient.post<DataWrapper>("/customer/passwordResetQuery", data)
}

export const userLoginQueryApi = async (data: any) => {
    return httpClient.post<DataWrapper>("/customer/accountLoginQuery", data)
}

interface ForgotPasswordOTP {
    mode: "otp-capture"
    cellPhone: string
    token: string
}



export const userForgotPasswordOTP = async (cellphone: string) => {
    return httpClient.post<DataWrapper<ForgotPasswordOTP>>("/customer/auth/sendOTPForgetPassword", {
        cellphone
    })
}

export const userUpdateNumber = async (data: {
    pre_auth_token: string, 
    newNumber: string, 
    oldNumber: string
}) => {
    return httpClient.post<DataWrapper>("/customer/auth/updatePhoneNumber", data)
}
