import { useEffect, useRef } from "react";
import { DevicePayload, LoginResponse, registerAnonDevice, setDeviceTokenPayload, usersetDeviceToken } from "../../../api/user";
import { clearDeviceTokenLocal, getDeviceTokenLocal, setDeviceTokenLocal } from "./helper";

interface TokenInitRequest {
    brandSite: string
    userId: string
    userEmail: string
    userFirstName: string
    userLastName: string
    userCellphone: string
}

const useDeviceTokenManager = (loggedIn: boolean) => {
    const inited = useRef(false)

    

    const setDeviceToken = async (data: setDeviceTokenPayload) => {
        const resp = await usersetDeviceToken(data)
        if (resp.status !== 200) {
            console.log("@err_setting_token_to_db", resp)
            return
        }

        console.log("@set_token_to_db_ok")
    }

    // registerAnonDevice

    const registerAnon = async (data: DevicePayload) => {
        const resp = await registerAnonDevice(data)
        if (resp.status !== 200) {
            console.log("@err_setting_token_to_db", resp)
            return
        }

        console.log("@set_token_to_db_ok")
    }

    const processRNToken = (data: Record<string, any>) => {
        if (data.type === "FCM_TOKEN" || data.type === "REPLY_TOKEN") {
            const payload = {
                platform: data.platform || "web",
                appVersion: data.appVersion,
                manufacturer: data.manufacturer,
                osVersion: data.osVersion,
                deviceModel: data.deviceModel,
                token: data.token,
            }
            const oldToken = getDeviceTokenLocal()
            if (oldToken === data.token) {
                console.log("@old_device_token is equal to new/skip", oldToken)
                return
            }

            setDeviceTokenLocal(data.token)

            if (loggedIn) {
                setDeviceToken(payload)    
            } else {
                registerAnon(payload)
            }


        }
    }



    useEffect(() => {
        const watchForTokens = (ev: any) => {
            if (!ev?.data?.startsWith("{")) {
                console.log("skipping@watchForTokens/not_json_msg", ev);
                return
            }


            const data = JSON.parse(ev.data) as { type: string, token: string } & Record<string, any>
            processRNToken(data)
        };

        window.addEventListener("message", watchForTokens);

        return () => {
            window.removeEventListener("message", watchForTokens);
        };
    }, []);

    const askForToken = (payload: Partial<TokenInitRequest>) => {

        if (typeof window.ReactNativeWebView !== undefined) {
            // window?.ReactNativeWebView?.postMessage("REQUEST_FCM_TOKEN");

            window?.ReactNativeWebView?.postMessage(JSON.stringify({
                type: "REQUEST_TOKEN",
                ...payload
            }));
        }
    }


    const askTokenOnLogin = (payload: LoginResponse) => {
        askForToken({
            brandSite: location.origin,
            userCellphone: payload.cellphone,
            userEmail: payload.cellphone,
            userId: payload.uuid,
            userFirstName: payload.firstName,
            userLastName: payload.lastName,
        })
    }

    const askTokenAnon = () => {
        askForToken({
            brandSite: location.origin,
        })
    }


    const init = () => {
        if (typeof window === "undefined") {
            return
        }

        if (typeof localStorage === "undefined") {
            return
        }

        const token = getDeviceTokenLocal()
        if (!token) {
            askTokenAnon()      
        }
    }


    useEffect(() => {
        if (inited.current) {
            return
        }
        
        inited.current = true

        init()

    }, [])


    const clearOnLogout = () => {
        clearDeviceTokenLocal()
    }


    return {
        processRNToken,
        askTokenOnLogin,
        askTokenAnon,
        setDeviceToken,
        clearOnLogout,
        askForToken
    }
}

export default useDeviceTokenManager