import { DataWrapper, httpClient } from "../http";

export interface UserInfo {
  uuid: string;
  cellphone: string;
  dateOfBirth: string;
  email: string;
  emailOffers: boolean;
  firstName: string;
  lastName: string;
  smsOffers: boolean;
  userName: string;
  dietaryCheck: string[];
  country: string;
  countryCode: string;
}

export const userGetInfo = async () => {
  return httpClient.get<DataWrapper<UserInfo>>(
    "/customer/users/currentUserDetail"
  );
};

export const deleteMeSelf = async () => {
  return httpClient.delete(
    "/customer/users/currentUserDelete"
  );
};


export const userUpdateInfo = async (data: Partial<UserInfo> & { ipaddr?: string }) => {
  return httpClient.post<DataWrapper>("/customer/users/updateUserDetail", data);
};

export const userChangePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  return httpClient.post<DataWrapper>("/auth/changePassword", {
    oldPassword,
    newPassword,
  });
};
