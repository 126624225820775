import { useEffect, useState } from "react";
import { StoreInfo } from "../../../api/store/storeQueryTypes";
import { StoreDetailedInfo } from "../../../state/models";
import { getStoreById } from "../../../api";

export interface State {
  store: StoreInfo | StoreDetailedInfo | null;
  serviceType?: ServiceType;
}

export type ServiceType =
  | "TAKE_AWAY"
  | "DELIVERY"
  | "DINE_IN"
  | "KERBSIDE_COLLEC";

const useActiveStore = () => {
  const [state, setState] = useState<State>({
    store: null,
  });

  useEffect(() => {
    if (!window || !window.localStorage) {
      return;
    }

    const data = localStorage.getItem("activeStore");
    if (data) {
      setState({ ...JSON.parse(data) });
    }
  }, []);

  useEffect(() => {
    if (!window || !window.localStorage) {
      return;
    }

    localStorage.setItem("activeStore", JSON.stringify(state));
  }, [state]);

  const setStore = (store: StoreInfo) => {
    setState({ store });
  };

  const setStoreWithServiceType = (
    store: StoreInfo,
    serviceType: ServiceType
  ) => {
    
    console.log("@setStoreWithServiceType", store, serviceType);

    setState({ store, serviceType });
  };

  const setServiceType = (svc: ServiceType) => {
    setState((old) => ({ ...old, serviceType: svc }));
  }
 
  const pickStore = async (storeId: number) => {
    console.log("fixme => call get store api pickStore", storeId);

    const resp = await getStoreById(storeId)
    if (resp.status !== 200) {
      return;
    }

    const store = resp.data as StoreDetailedInfo
    const stype = store.serviceType.Website[0].serviceType as ServiceType;

    setState((old) => ({  ...old, serviceType: stype, store }));    
  }

  return {
    state,
    setStore,
    pickStore,
    setServiceType,
    setStoreWithServiceType,
  };
};

export type ActiveStoreHandle = ReturnType<typeof useActiveStore>;

export default useActiveStore;
