import Axios, { AxiosResponse } from "axios";
import config from "../config/app";
import * as interceptor from "./interceptor";
import { getAuthToken, getPreAuthedToken } from "../state/hooks/useAuth/helper";
import axios from "axios";


const { ECommerce } = config;


const ecClient = Axios.create({
  baseURL: ECommerce.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});
const ecOrderClient = Axios.create({
  baseURL: `${ECommerce.BASE_URL}/order`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});
const ecStoreClient = Axios.create({
  baseURL: `${ECommerce.BASE_URL}/stores`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});


const ecUserClient = Axios.create({
  baseURL: `${ECommerce.BASE_URL}/customer`,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
  withCredentials: true,
});

// customer-api
ecUserClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecUserClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);



// ecommerce-api
ecClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);

// order-api
ecOrderClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecOrderClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);

// store-api
ecStoreClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
ecStoreClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);




export const getBannerImage = () =>
  ecClient({
    method: "get",
    url: `bannerImages`,
  });


export const ClientData = () =>
  ecUserClient({
    method: "get",
    url: `client`,
  });

export const createOrderApi = (params: any) =>
  ecOrderClient({
    method: "post",
    url: `createOrder`,
    data: { ...params },
  });

export const getStoreBoundries = (params: any) =>
  ecUserClient({
    method: "get",
    url: `store/boundries/${params.id}`,
  });

export const getStores = () =>
  ecStoreClient({
    method: "get",
    url: `stores/ecommerce`,
  });

export const getStoresWithQuery = (query: any) =>
  ecStoreClient({
    method: "post",
    url: `stores/ecommerce`,
    data: query,
  });

export const getSingleStoreDetailApi = (params: any) =>
  ecStoreClient({
    method: "get",
    url: `stores/${params.id}`,
  });

export const allMenus = (params: any) =>
  ecOrderClient({
    method: "post",
    url: "/menu",
    data: {
      storeUuid: params.storeUuid,
    },
  });
export const menu = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `/menu/${params.menuGroupId}/${params.menuId}`,
  });

export const apiCall = () =>
  ecClient({
    method: "get",
    url: `client`,
  });
export const getFaqData = () =>
  ecClient({
    method: "get",
    url: `faq`,
  });

export const orderDetailsApi = (params: any) =>
  ecOrderClient({
    method: "get",
    url: `/orders/ecommerceOrderDetails/${params.orderUuid}`,
  });


// static 

const isrClient = Axios.create({
  baseURL: ECommerce.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: ECommerce.timeout,
});


export const getClientContacts = (clientSlug: string) =>
  isrClient({
    url: "clientContact/active",
    method: "get",
    headers: {
      clientSlug, 
    }
  });

export const getClientDocuments = (clientSlug: string) =>
  isrClient({
    method: "get",
    url: `clientDocuments/active`,
    headers: {
      clientSlug, 
    }
  });

  export const getClientTocDocuments = (clientSlug: string) =>
    isrClient({
      method: "get",
      url: `clientDocuments/tocWithContents`,
      headers: {
        clientSlug, 
      }
    });

export const getClientDocumentById = (clientSlug: string, id: string) =>
  isrClient({
    method: "get",
    url: `clientDocuments/specific/${id}`,
    headers: {
      clientSlug, 
    }
  });


export const getFaqData2 = (clientSlug: string) =>
  isrClient({
    method: "get",
    url: `faqs/active`,
    headers: {
      clientSlug, 
    }
  });


  export const getClientFranchiseDocuments = (clientSlug: string) =>
    isrClient({
      method: "get",
      url: `franchise/document`,
      headers: {
        clientSlug,
      }
    });
  

// new menu api 


export const getDeploymentInfo = (storeUuid: string, channelCode?: string) =>
  ecStoreClient({
    method: "get",
    url: `menu/deploymentInfo/${storeUuid}?channelCode=${channelCode}`,
  });



export const getActiveMenu = (storeUuid: string, channelCode?: string) =>
  ecStoreClient({
    method: "get",
    url: `menu/active/${storeUuid}?channelCode=${channelCode}`,
  });


  export const getActiveMenuCategories = (storeUuid: string, channelCode?: string) =>
  ecStoreClient({
    method: "get",
    url: `menu/active/catagories/${storeUuid}?channelCode=${channelCode}`,
  });

  ///active/catagories/:storeUuid


export const getMenuItem = (deployment: string, itemId: string) => {
  return axios.get(`${ECommerce.BASE_URL}/stores/menu/menuItem/${deployment}/${itemId}/image.png`, {
    headers: {
    //  "Content-Type": "application/json",
    }
  })
}

export const getMenuVariantByParentId = (deployment: string, itemId: string, parentId: string) => {
  return axios.get(`${ECommerce.BASE_URL}/stores/menu/variantByParentId/${deployment}/${itemId}/${parentId}/image.png`, {
    headers: {
    //  "Content-Type": "application/json",

    }
  })
}


// order api


export const createOrder = (data: any) => ecOrderClient({
  method: "post",
  url: "/new/ecommerce",
  data
})

export const getOrderById = (orderId: string) => ecOrderClient({
  method: "get",
  url: `/${orderId}`,
})

export const getUserOrderTrackInfo = (orderId: string) => ecOrderClient({
  method: "get",
  url: `/trackInfo/${orderId}`,
})


export const getUserOrderLiveStatus = (orderId: string): Promise<AxiosResponse<{orderStatus: string}>> => ecOrderClient({
  method: "get",
  url: `/liveStatus/${orderId}`,
})

export const getOrderWsURL = (orderId: string) => {
  const userAuthData = getAuthToken();
  const accessToken = userAuthData?.accessToken;
  let url = `${ECommerce.BASE_URL}/order/ws/${orderId}?token=${accessToken}`

  url.replace("http", "ws")

  return url
}
