import { useModalState, ModalContext } from "./modal"

const WithMainModal = ({ children }: any) => {

  const state = useModalState();
  const { _inner_state: { shown, compo: InnerComponent, props } } = state


  return (

    <>
    
      {shown && (<>
        <div className="py-12 bg-gray-700/60 transition duration-150 ease-in-out z-[101] fixed top-0 right-0 bottom-0 left-0"
          id="modal">
          <button className="absolute top-2 right-2"
            aria-label="close"
            onClick={state.close}

          >
            <svg height="100%" width="100%" viewBox="0 0 9 9" fill="none" className="w-4 h-4">
              <path d="M9 0.906428L8.09357 0L4.5 3.59357L0.906428 0L0 0.906428L3.59357 4.5L0 8.09357L0.906428 9L4.5 5.40643L8.09357 9L9 8.09357L5.40643 4.5L9 0.906428Z" fill="white" />
            </svg>
          </button>

          <div role="alert" className="w-full h-full ">
              {InnerComponent && <InnerComponent {...(props || {})}></InnerComponent>}
            </div>
        </div>

      </>)}





      <ModalContext.Provider value={state}>
        {children}
      </ModalContext.Provider>

    </>

  );
};


export default WithMainModal;
