

interface PropsType {
    content: string
    close: any
    className?: string
    typeIcon?: string
}


const PopUp = (props: PropsType) => {
    return (<>
        <div className={`bg-white mx-auto max-w-md rounded-md flex flex-col sup-card gap-4 p-4 ${props.className || ""}`}>
            <div className="flex gap-2">
                <div className="w-6 h-6 relative">
                    <svg>
                        <use href={`/sprites/sprites.svg#${props.typeIcon || "infoIcon"}`} />
                    </svg>
                </div>
                <h2>{props.content}</h2>
            </div>

            <button
                    className="flex px-[8px] rounded-[4px] self-stretch justify-center items-center w-full min-h-[32px] text-white sup-btn bg-accents-primary-button"
                    onClick={props.close}
                >
                    Close
                </button>

        </div>
    </>)
}

export default PopUp;