import axios from "axios";
import config from "../config/app";
import { decrypt } from "../utils/commonFunction";

const { EncrytionDecryptionRequired } = config;

const commonISRApi = async (clientSlug: string, method: string, URL: any, params?: any) => {
  try {
    const urlData: any = {
      method,
      headers: {
        clientSlug,
      },
      body: params,
    };
    const res = await fetch(URL, urlData);
    return res.json();
  } catch (error: any) {
    console.log("$$err", error);
  }
};

const getClientData = async (clientSlug: string) => {
  return getOpUnitBySlug(clientSlug);
};

const getRequest = async (clientSlug: string, method: any, url: any) => {
  const localSet: any = {
    method,
    url,
    headers: {
      clientSlug,
      decryption_enabled: EncrytionDecryptionRequired,
    },
  };
  return axios(localSet)
    .then((response) => {
      if (response && EncrytionDecryptionRequired) {
        const decyrptedPayload = decrypt(response?.data?.data);
        response.data.data = JSON.parse(decyrptedPayload as any);
      }
      return response?.data?.data ?? null;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getStoresPublicStores = async (clientSlug: string) => {
  const url = config.ECommerce.BASE_URL + "/client/storeTags/public/getAllOpUnitStores";

  try {
    const response = await axios({
      method: "GET",
      url,
      headers: {
        clientSlug,
      },
    });

    return response.data;
  } catch (error) {
    console.error("An error occurred while making the request:", error);
    throw error;
  }
}


export const getPublicOpUnits = async (): Promise<{
  uuid: string,
  websiteSlug: string,
  countryCode: string,
  brandUuid: string,
}[]> => {
  const url = config.ECommerce.BASE_URL + "/client/opUnit/public/all";

  try {
    const response = await axios({
      method: "GET",
      url,
    });

    return response.data;
  } catch (error) {
    console.error("An error occurred while making the request:", error);
    throw error;
  }

}


export interface OpUnit {
  uuid: string,
  brandUuid: string,
  websiteSlug: string,
  countryCode: string,
  websiteConfig: Record<string, any>,
}

export const getOpUnitByHost = async (name: string): Promise<OpUnit> => {

  if (name.includes("/")) {
    name = name.split("/")[0];
  }

  const url = config.ECommerce.BASE_URL + `/client/opUnit/public/byHost/${name}`;

  console.log("@@url", url);

  try {
    const response = await axios({
      method: "GET",
      url,
    });

    if (typeof response.data.websiteConfig === "string") {
      response.data.websiteConfig = JSON.parse(response.data.websiteConfig);
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred while making the request:", error);
    throw error;
  }
}

export const getOpUnitBySlug = async (slug: string): Promise<OpUnit> => {

  const url = config.ECommerce.BASE_URL + `/client/opUnit/public/${slug}`;

  console.log("@@url", url);

  try {
    const response = await axios({
      method: "GET",
      url,
    });

  
    if (typeof response.data.websiteConfig === "string") {
      response.data.websiteConfig = JSON.parse(response.data.websiteConfig);
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred while making the request:", error);
    throw error;
  }

}



export { commonISRApi, getClientData, getRequest };
