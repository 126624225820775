import { subItemPriceObject, sumOfOptInputs } from "../hooks";
import { InputState, ItemPriceData, MenuItemData, MenuItemInputState, MenuItemPrice, MenuItemState } from "../models";
import { CartItem } from "../models/cart";
import { MenuLoader } from "../models/renderer";
import { InputCartOptions, inputToCartWithOptions } from "./inputToCart";
import { extractVariantPath } from "./inputToVariantPath";
import { menuItemFormatter, variantFormatter } from "./rawFormatter";

interface RecalcOptions {
    input: InputState
    loader: MenuLoader
    serviceType: string
    deployementId: string
    menuItemId: string
    menuId: string
    currentCartItem: CartItem
}

/*
    totalprice = FOR_ALL_MENU_ITEMS ( menuItemPrice + allOptionsPrice + variantPrice )

*/

// vegetrian -> small -> pan [pickup 38 / delivery 99]

export const reCalculateItemPrices = async (opts: RecalcOptions) => {

    const calculateMenuItem = async (minput: MenuItemInputState, data: MenuItemData): Promise<ItemPriceData> => {


        const rootOptions = minput["root"]

        const menuItemPrice = Number(data.price?.price || 0)
        let rootOptionTotal = 0;
        let variantOptionTotal = 0
        let variantPrice = 0


        if (rootOptions) {
            Object.values(rootOptions.optionInputs).forEach((optInput) => {
                const optPrice = sumOfOptInputs(data.options, optInput)
                rootOptionTotal = rootOptionTotal + optPrice;
            })
        }

        for (const vKey in minput) {
            if (vKey === "root") {
                continue
            }

            const vInputs = minput[vKey]

            const rawVariant = await opts.loader.LoadVariant(opts.menuId, opts.deployementId, data.id, vKey)

            //console.log("@rawVariant", rawVariant, vKey)

            const varData = variantFormatter(opts.serviceType, rawVariant)

            data.variants[varData.id] = varData


            Object.values(vInputs.optionInputs).forEach((optInput) => {
                const optPrice = sumOfOptInputs(varData.options, optInput)
                variantOptionTotal = variantOptionTotal + optPrice;
            })

            const nextVariantPrice = Number(varData.price?.price || 0)
            if (nextVariantPrice > variantPrice) {
                variantPrice = nextVariantPrice
            }
        }

        return {
            baseItemAmount: menuItemPrice,
            itemOptionsTotalAmount: rootOptionTotal,
            variantBaseAmount: 0,
            variantOptionsTotalAmount:
                variantOptionTotal,
            variantSelectedAmount: variantPrice
        }

    }

    const rawMenuItem = await opts.loader.LoadMenu(opts.menuId, opts.deployementId, opts.menuItemId);
    const menuItemData = menuItemFormatter(opts.serviceType, rawMenuItem)

    const total = await calculateMenuItem(opts.input[opts.menuItemId] || {}, menuItemData)

    const inputCartOptions: InputCartOptions = {
        mainMenuItemId: opts.menuItemId,
        subMenuItems: {},
        cartId: opts.currentCartItem.cartItemId,
        count: opts.currentCartItem.count,
        itemAmount: total.variantSelectedAmount + total.baseItemAmount,
        extraAmount: total.itemOptionsTotalAmount + total.variantOptionsTotalAmount,
        input: opts.currentCartItem.inputs,
        itemNotes: opts.currentCartItem.notes,
        subMenuItemPrices: {},
    }

    inputCartOptions.subMenuItems[opts.menuItemId] = {
        itemKey: {
            menuItemId: opts.menuItemId,            
        },
        status: "loaded",
        variantsPaths: extractVariantPath(opts.menuItemId, opts.currentCartItem.inputs),
        apiData: menuItemData,
    }

    const prices: Record<string, MenuItemPrice> = {}

    for (const subMenuItemKey in opts.currentCartItem.subMenuItemPrices) {

        const subMenuItem = opts.currentCartItem.subMenuItemPrices[subMenuItemKey]

        const rawSubMenuItem = await opts.loader.LoadMenu(opts.menuId, opts.deployementId, subMenuItem.key.menuItemId);
        const submenuItemData = menuItemFormatter(opts.serviceType, rawSubMenuItem)

        console.log("@submenuItemData", submenuItemData, subMenuItem.key.menuItemId)

        const subMenuItemTotal = await calculateMenuItem(opts.input[subMenuItemKey] || {}, submenuItemData)

        const optData = menuItemData.options[subMenuItem.key.optionId as number]
        const subOptData = optData.subOptions[subMenuItem.key.subOptionId as number]

        const skiplist = optData.extrameta["variant_skip_list"]


        prices[subMenuItemKey] = subItemPriceObject(subOptData, subMenuItemTotal, subMenuItem.key)
        inputCartOptions.subMenuItems[subMenuItemKey] = {
            itemKey: subMenuItem.key,
            status: "loaded",
            variantsPaths: extractVariantPath(subMenuItemKey, opts.currentCartItem.inputs),
            apiData: submenuItemData,
            parentOptionCachedData: optData,
            skipListData: skiplist
        }

    }


    const newCartItem = inputToCartWithOptions(inputCartOptions)
    newCartItem.subMenuItemPrices = prices




    return newCartItem
}