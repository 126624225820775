import type { NextApiRequest, NextApiResponse } from "next";
import md5 from "crypto-js/md5"
import { getOpUnitByHost, getOpUnitBySlug } from "../../state/commonApiService";
import { SiteJson } from "../../state/models/client";
import { convertFontAttrsToCss, convertJsonToCss } from "../../state/hooks/utils/json2css";
import { domainExtractor, stylerCssCacheDataIndex, stylerCssCacheFreshNessIndex , stylerCssHashIndex  } from "./cache";

const cacheDataIndex = stylerCssCacheDataIndex;
const cacheFreshNessIndex = stylerCssCacheFreshNessIndex;
const hashIndex = stylerCssHashIndex;



// http://romans.localhost:3001/ or https://barcelos.example.com



export default async function handler(req: NextApiRequest, res: NextApiResponse<any>) {
  try {
    const clientSlug = domainExtractor(req)
    

    console.log("@styler_css/1", clientSlug)

    let cacheData = cacheDataIndex[clientSlug]
    let cacheFreshNess = cacheFreshNessIndex[clientSlug]
    let hash = hashIndex[clientSlug]


    if (cacheData) {
      console.log("@styler_css/2")

      const diffsec = (((new Date()).getTime() - cacheFreshNess.getTime()) / 1000)
      if (diffsec < 600) {
        console.log("@styler_css/3/usin_cache")

        const currentEtag = req.headers["if-none-match"]
        if (currentEtag === hash) {
          console.log("@styler_css/4/cache_hit_no_resp_needed", currentEtag)
          return res.status(304).json({"ok": true})   
        }

        console.log("@styler_css/5")

        res.writeHead(200, { ETag: hash, "cache-control": "public, max-age=600" });
        console.log("@styler_css/6")

        res.setHeader('Content-Type', 'text/css');

        console.log("@styler_css/7")

        return res.send(cacheData);
      }
    }

    const data = await getOpUnitByHost(clientSlug)
    if (!data) {
       throw "no data"
    }
   

    console.log("@styler_css/8")

    //// BUILD CSS STRING START

    const siteJson = (data.websiteConfig || {}) as SiteJson

    const datastr = siteJsonToCss(siteJson)


    /// BUILD CSS STRING END


    console.log("@styler_css/9")

    cacheDataIndex[clientSlug] = datastr
    cacheFreshNessIndex[clientSlug] = new Date()
    hash = md5(datastr).toString()
    hashIndex[clientSlug] = hash

    console.log("@styler_css/10")

    const currentEtag = req.headers["if-none-match"]
    if (currentEtag === hash) {
      console.log("@styler_css/11")
      return res.status(304).json({"ok": true})
    }

    console.log("@styler_css/12")


    res.writeHead(200, { ETag: hash, "cache-control": "public, max-age=600" });
    console.log("@styler_css/13")

    res.setHeader('Content-Type', 'text/css');

    console.log("@styler_css/14")

    return res.send(datastr);

  } catch (err) {
    console.error(err);
    return res.status(500).json({ error: err });
  }

}

export const siteJsonToCss = (siteJson: SiteJson) => {
  console.log("@styler_css/8.0/siteJsonToCss", siteJson)

  const newStyle: Record<string, string> = Object.entries(siteJson.variables || {}).reduce(
    (acc: any, [key, value]: any) => {
      if (typeof value === "string" && value.includes("https")) {
        acc[`--${key}`] = `url("${value}")`;
      } else {
        acc[`--${key}`] = value;
      }
      return acc;
    },
    {}
  );


  let datastr = "*, ::before, ::after {"

  Object.entries(newStyle).forEach(([ key, value ]) => {
    datastr = datastr + `${key}:${value};`
  })


  datastr = datastr + "}"
  datastr =  convertFontAttrsToCss(siteJson.fonts || {}) + datastr;
  const customCss = siteJson.customCss || ""

  console.log("@styler_css/8.1", customCss)


  datastr = datastr + `\n\n/* STYLES ATTRS */ \n\n`
  datastr = datastr + convertJsonToCss(siteJson.stylesAttributes || {});
  datastr = datastr + `\n\n/* CUSTOM CSSS */ \n\n`
  datastr = datastr + customCss
  datastr = datastr + "\n\n" 

  console.log("@styler_css/8.2/final", datastr)
  
  return datastr
}
