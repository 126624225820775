import { ItemKey } from "../models/state"

export const calculateHash = (key: ItemKey) => {

    let hash = `${key.menuItemId}`
    if (key.optionId) {
        hash = hash + "_"
        hash = hash + key.optionId
    }

    if (key.subOptionId) {
        hash = hash + "_"
        hash = hash + key.subOptionId
    }

    if (key.parentKey) {
        hash = hash + "#"
        hash = hash + key.parentKey
    }

    return hash
}

export const formatPrice = (price: string | number) => {

    const numberPrice = typeof price === "number" ? Number(price) : parseFloat(price);
    if (isNaN(numberPrice)) {
        return String(price)  ;
    }

    return numberPrice.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export const currencyFormatted = (price: string | number, space?: boolean) => {
    
    let currSymbol = "";

    if (typeof window !== "undefined") {
        currSymbol = window["__sup_curr_symbol__"] || ""
    }
    

    const formatedPrice = formatPrice(price);
    return `${currSymbol}${space ? " ": ""}${formatedPrice}`;
}


export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const makeid = (length: number) => {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};