import * as rtype from "../../SuperRenderer/sub/models/raw"



export interface GlobalCache {
    getMenuItem: (deploymentId: string, menuItemId: string) => rtype.DeployedMenuItemData | void
    setMenuItem: (item: rtype.DeployedMenuItemData) => void

    getVariant: (deploymentId: string, menuItemId: string, variantId: string) => rtype.DeployedMenuVariant | void
    setVariant: (item: rtype.DeployedMenuVariant) => void
}


export class GlobalApiCache {
    menuItems: Map<string, rtype.DeployedMenuItemData>
    menuVariants: Map<string, rtype.DeployedMenuVariant>
    deploymentId: string | undefined
    maxMenuItems: number
    maxVariants: number

    constructor() {
        this.menuItems = new Map()
        this.menuVariants = new Map()
        this.maxMenuItems = 10
        this.maxVariants = 10 * 2
    }

    getMenuItem = (deploymentId: string, menuItemId: string) => {
        if (this.deploymentId !== deploymentId) {
            this.menuVariants.clear()
            this.menuItems.clear()
            return
        }

        const key = `${deploymentId}_${menuItemId}`

        const hasKey = this.menuItems.has(key);
        let entry: rtype.DeployedMenuItemData | undefined;
        if (hasKey) {
            entry = this.menuItems.get(key);
            this.menuItems.delete(key);
            this.menuItems.set(key, entry as rtype.DeployedMenuItemData);
            console.log("cache/menu/hit", key)
        }

        return entry
    }

    setMenuItem = (item: rtype.DeployedMenuItemData) => {
        const mitem = item.menuItemData

        if (!this.deploymentId) {
            this.deploymentId = mitem.deploymentId
        }

        if (mitem.deploymentId !== this.deploymentId) {
            this.menuVariants.clear()
            this.menuItems.clear()
        }

        const key = `${mitem.deploymentId}_${mitem.id}`

        if (this.menuItems.size >= this.maxMenuItems) {
            const keyToDelete = this.menuItems.keys().next().value;
            this.menuItems.delete(keyToDelete as string);
        }

        this.menuItems.set(key, item);
    }



    getVariant = (deploymentId: string, menuItemId: string, variantId: string) => {
        if (this.deploymentId !== deploymentId) {
            this.menuVariants.clear()
            this.menuItems.clear()
            return
        }

        const key = `${deploymentId}_${menuItemId}_${variantId}`


        const hasKey = this.menuVariants.has(key);
        let entry: rtype.DeployedMenuVariant | undefined;
        if (hasKey) {
            entry = this.menuVariants.get(key);
            this.menuVariants.delete(key);
            this.menuVariants.set(key, entry as rtype.DeployedMenuVariant);
            console.log("cache/variant/hit", key)
        }

        return entry
    }

    setVariant = (item: rtype.DeployedMenuVariant) => {
        if (!this.deploymentId) {
            this.deploymentId = item.deploymentId
        }

        if (item.deploymentId !== this.deploymentId) {
            this.menuVariants.clear()
            this.menuItems.clear()
        }


        const key = `${item.deploymentId}_${item.deployedMenuItemId}_${item.id}`

        if (this.menuVariants.size >= this.maxVariants) {
            const keyToDelete = this.menuVariants.keys().next().value;
            this.menuVariants.delete(keyToDelete as string);
        }

        this.menuVariants.set(key, item);

    }



}

