import { useEffect, useState } from "react"
import { ItemPriceData } from "../../models"
import { InputHandle, sumOfOptInputs } from "../useInput"
import { RootHandle } from "../useRoot"




const INITIAL_VALUE: ItemPriceData = {
    baseItemAmount: 0,
    itemOptionsTotalAmount: 0,

    variantBaseAmount: 0,
    variantSelectedAmount: 0,
    variantOptionsTotalAmount: 0
}

interface PriceStateData extends ItemPriceData {
    count: number   
}



export const usePrice = ( root: RootHandle, input: InputHandle, count: number) => {
    const [state, setState] = useState<PriceStateData>({ ...INITIAL_VALUE, count, })

    const increaseCount = () => {

        setState((old) => {
            return { ...old, count: old.count + 1 }
        })

    }

    const decreaseCount = () => {
        setState((old) => {
            const ncount = old.count - 1;
            if (ncount < 1) {
                return old
            }

            return { ...old, count: ncount }
        })
    }


    const calculatePrice = () => {

        const data = root.state.menuItems[root.state.activeItemKey]
        if (!data || !data.apiData) {
            console.log("@SKIPPING_PRICE_CALC/NO_API_DATA")
            return
        }

        const variants = data.apiData.variants;
        const sInput = input.state[root.state.activeItemKey] || {}


        let nextBaselineVariantAmount = 0;
        let nextSelectedVariantAmount = 0
        let nextVariantOptionsTotalAmount = 0


        const traverseAndCalculatedPrice = (vid?: string): void => {
            if (!vid) {
                return
            }

            console.log("@traverseAndCalculatedPrice", vid)



            const vinputs = sInput[vid]
            const vData = variants[vid]

            


            if (vinputs && vData) {
                console.log("@inside_price/vinput", vinputs)

                const price = ((vData.childrens[vinputs.selectedSubVariant as string] || {}).price?.price || 0);
                const basePrice = ((vData.childrens[vinputs.basePriceVariant as string] || {}).price?.price || 0)
                if (basePrice || price) {
                    nextSelectedVariantAmount = Number(price)
                    nextBaselineVariantAmount = Number(basePrice)
                    console.log("@MEAT_OF_PRICE", nextSelectedVariantAmount, nextBaselineVariantAmount)                
                }

                traverseAndCalculatedPrice(vinputs.selectedSubVariant)
            }

            if (vData && vinputs) {
                let currentSum = 0

                console.log("@variant/extras", vinputs)

                Object.values(vinputs.optionInputs).forEach((oinput) => {
                    currentSum = currentSum + sumOfOptInputs(vData.options, oinput)
                    console.log("@currrent_sum", currentSum)
                })

                nextVariantOptionsTotalAmount = nextVariantOptionsTotalAmount + currentSum;
            }
        }


        if (data.variantsPaths.length) {
            traverseAndCalculatedPrice(data.variantsPaths[0])
            console.log("@AFTER_ALL_VARIANT_AMOUNT/BASE", nextBaselineVariantAmount)
            console.log("@AFTER_ALL_VARIANT_AMOUNT/SELECTED", nextSelectedVariantAmount)
            console.log("@AFTER_ALL_VARIANT_AMOUNT/ALL_EXTRA", nextVariantOptionsTotalAmount)
        }

        

        const rootPrices = data.apiData.options;
        const rootInputs = sInput["root"]


        let nextExtrasMenuItemAmount = 0
        if (rootPrices && rootInputs) {
            console.log("@CALC_ROOT_START", rootPrices, rootInputs)
            Object.values(rootInputs.optionInputs).forEach((oinput) => {
                console.log("@CALC_START_MIDDLE", oinput.id)

                nextExtrasMenuItemAmount = nextExtrasMenuItemAmount + sumOfOptInputs(rootPrices, oinput)
            })

            console.log("@AFTER_ALL_ROOT_OPTIONS", nextExtrasMenuItemAmount)

            console.log("@CALC_ROOT_END")
        }

        



        let nextBaseMenuItemAmount = Number(data.apiData.price?.price || 0) ;

        console.log("@ONLY_MENU_ITEM_PRICE", nextBaseMenuItemAmount)


        setState((old) => {
            return {
                count: old.count,
                baseItemAmount: nextBaseMenuItemAmount,
                variantOptionsTotalAmount: nextVariantOptionsTotalAmount,
                variantBaseAmount: nextBaselineVariantAmount,
                itemOptionsTotalAmount: nextExtrasMenuItemAmount,
                variantSelectedAmount: nextSelectedVariantAmount,
            }
        })

    }


    const getTotal = () => {

        return { 
            itemAmount: state.variantSelectedAmount +  state.baseItemAmount,
            extraAmount: state.itemOptionsTotalAmount  + state.variantOptionsTotalAmount,
        }
    }




    useEffect(() => {

        console.group("@CALCULATING_PRICE")
        calculatePrice()
        console.groupEnd()

    }, [root.state, input.state])




    return { state, increaseCount, decreaseCount, getTotal }
}
