import { InputState } from "../models"


export const extractVariantPath = (key: string, inputs?: InputState): string[] => {
    const variantPaths: string[] = []
    if (!inputs) {
        return variantPaths
    }

    const itemInputState = ((inputs)[key] || {})
    const reverseIndex: { [_: string]: boolean } = {}
    const subs = Object.values(itemInputState)

    subs.forEach((item) => {
        if (!item.selectedSubVariant) {
            return
        }

        reverseIndex[item.selectedSubVariant] = true
    })

    const root = subs.find((item) => {
        if (item.id === "root") {
            return false
        }

        if (reverseIndex[item.id]) {
            return false
        }

        if (!item.selectedSubVariant) {
            return false
        }
        return true

    })

    if (!root) {
        console.log("@not_found_in_index", reverseIndex)
        return variantPaths
    }

    variantPaths.push(root.id)

    const buildVariantPath = (vid?: string) => {
        if (!vid) {
            return
        }

        variantPaths.push(vid)
        const vInputState = itemInputState[vid]
        if (vInputState) {
            buildVariantPath(vInputState.selectedSubVariant)
        }
    }

    buildVariantPath(root.selectedSubVariant)


    console.log("@variantPaths", variantPaths)


    return variantPaths
}
