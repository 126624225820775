import { useEffect, useState } from "react"
import { useMaxOptionCount } from "./useMaxOptionCount"
import { RootHandle } from "../useRoot"
import { InputHandle, isEveryOptionDone, isEveryVariantDone } from "../useInput"
import { usePrice } from "./usePrice"
import { MenuItemState } from "../../models"


export const useDerived = (root: RootHandle, input: InputHandle, count: number) => {

    const maxOptionCount = useMaxOptionCount(root, input)
    const price = usePrice(root, input, count)
    const [activeMenuItem, setActiveMenuItem] = useState<MenuItemState | undefined>()
    const [canAddToCart, setCanAddToCart] = useState(false)


    useEffect(() => {

        if (!activeMenuItem || !activeMenuItem.apiData) {            
            setCanAddToCart(false)
            return
        }
        
        const sInput = input.state[root.state.activeItemKey] || {}
        const variants = activeMenuItem.apiData.variants || {}


        const rootVariantDone = (activeMenuItem.variantsPaths || []).
            every((v) => {
                const variant = variants[v]
                if (!variant) {
                    return false
                }
                
                return isEveryVariantDone(sInput, activeMenuItem?.apiData as any, variant)
            })


        const rootOptionDone = isEveryOptionDone(activeMenuItem.apiData.options || {}, sInput?.root?.optionInputs || {} )
        
        console.log("@setCanAddToCart", rootOptionDone, rootVariantDone)

        setCanAddToCart(rootOptionDone && rootVariantDone)
    }, [root.state, input.state, activeMenuItem ])


    useEffect(() => {

        const menuItem = root.state.menuItems[root.state.activeItemKey]

        if (!menuItem) {
            return
        }

        setActiveMenuItem(menuItem)


    }, [root.state])


    return { maxOptionCount, price, activeMenuItem, canAddToCart }
}


export type DerivedHandle = ReturnType<typeof useDerived>


