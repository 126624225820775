import { useState } from "react"
import {
    OptionModeType,
    InputState,
    MenuItemInputState,
    OptionInputState,
    VariantData,
    MenuItemData,
    OptionData
} from "../models"
import { RootHandle } from "./useRoot"


const useInput = (root: RootHandle,  initialState?: any) => {
    const [state, setState] = useState<InputState>(initialState || {})


    const everyVariantDone = (variant: VariantData): boolean => {
        const activeMenuItem  = root.getActiveItem()
        const subState = state[root.state.activeItemKey] || {}        
        const menuItem = activeMenuItem.apiData as MenuItemData

        console.assert(activeMenuItem.apiData !== undefined, "activeMenuItem.apiData should not be empty")


        return isEveryVariantDone(subState, menuItem, variant)
    }


    const setChooseSubVariant = (scopeKey: string, parentId: string, childId: string, basePriceVariant?: string) => {

        console.log("@choosing_sub_variant", parentId, childId)

        setState((old) => {
            let subscope = old[scopeKey]
            if (!subscope) {
                subscope = {}
                old[scopeKey] = subscope
            }

            const oldpstate = subscope[parentId]
            if (!oldpstate) {
                subscope[parentId] = {
                    id: parentId,
                    optionInputs: {},
                    selectedSubVariant: childId,
                    basePriceVariant: basePriceVariant
                }

            } else {
                oldpstate.selectedSubVariant = childId
                oldpstate.basePriceVariant = basePriceVariant
            }

            return { ...old }
        })
    }

    const setOptionState = (scopeKey: string, variantid: string, optionId: number, suboption: number, mode: OptionModeType, val?: any) => {

        let subscope = state[scopeKey]
        if (!subscope) {
            subscope = {}
            state[scopeKey] = subscope
        }


        const varState = subscope[variantid]
        if (!varState) {
            subscope[variantid] = {
                id: variantid,
                optionInputs: {
                    [optionId]: {
                        id: optionId,
                        modes: { [suboption]: mode },

                        radioSelection: suboption,
                        selection: { [suboption]: true },
                        counterSeclection: {
                            [suboption]: val || 0,
                        }
                    }
                }
            }
        } else {
            const optState = varState.optionInputs[optionId]
            if (!optState) {
                varState.optionInputs[optionId] = {
                    id: optionId,

                    modes: { [suboption]: mode },
                    radioSelection: suboption,
                    counterSeclection: { [suboption]: val || 0 },
                    selection: { [suboption]: true }
                }
            } else {

                optState.radioSelection = suboption
                if (optState.selection[suboption]) {
                    delete optState.selection[suboption]
                    console.log("@delete")
                } else {
                    optState.selection[suboption] = true
                    console.log("@add")
                }

                optState.modes[suboption] = mode

                optState.counterSeclection[suboption] = val || 0
            }
        }

        setState({ ...state })

    }

    const setRootOptionState = (scopeKey: string, optionId: number, suboption: number, mode: OptionModeType, val?: any) => {


        let subscope = state[scopeKey]
        if (!subscope) {
            subscope = {}
            state[scopeKey] = subscope
        }


        let root_option_input = subscope["root"]
        if (!root_option_input) {
            root_option_input = {
                id: "root",
                optionInputs: {},
            }

            subscope["root"] = root_option_input
        }

        console.log("@_______OPTION_HERE_____", optionId)

        const optInput = root_option_input.optionInputs[optionId]
        if (optInput) {
            optInput.counterSeclection[suboption] = val || 0
            optInput.radioSelection = suboption
            optInput.modes[suboption] = mode


            if (optInput.selection[suboption]) {
                delete optInput.selection[suboption]
                console.log("@deleting")
            } else {
                optInput.selection[suboption] = true
                console.log("@adding")
            }


        } else {
            console.log("@ONLY_THIS_PATH")
            root_option_input.optionInputs[optionId] = {
                radioSelection: suboption,
                counterSeclection: { [suboption]: val || 0 },
                id: optionId,

                selection: { [suboption]: true },
                modes: { [suboption]: mode }

            }
        }


        setState({ ...state })


    }


    // for submenu selection
    const cleanOldSubMenuSelection = (scopeKey: string, parentScopeKey: string, parentVariantKey: string, optionId: number, subOptId: number) => {

        console.log("@cleaning", {
            scopeKey,
            parentScopeKey,
            parentVariantKey,
            optionId,
            subOptId
        })


        setState((old) => {

            const parent = old[parentScopeKey]
            if (!parent) {
                return old
            }

            const vInputs = parent[parentVariantKey]
            if (!vInputs) {
                return old
            }

            const optInputs = vInputs.optionInputs[optionId]
            if (!optInputs) {
                return old
            }

            const subMode = optInputs.modes[subOptId]
            if (!subMode) {
                return old
            }

            switch (subMode) {
                case "checkbox":
                    delete optInputs.selection[subOptId]
                    break;
                case "radio":
                    optInputs.radioSelection = 0
                    break;
            }





            delete old[scopeKey]


            return { ...old }
        })

    }



    // for variant selction
    const cleanOldSelection = (scopeKey: string, from: string) => {
        const selections: string[] = []

        const subscope = state[scopeKey] || {}

        const buildClearList = (id: string) => {
            const item = subscope[id]
            if (!item) {
                return
            }

            selections.push(id)

            if (item.selectedSubVariant) {
                buildClearList(item.selectedSubVariant)
            }
        }

        const item = subscope[from]
        if (item && item.selectedSubVariant) {
            buildClearList(item.selectedSubVariant)
        }



        setState((old) => {

            const subscope = old[scopeKey] || {}


            selections.forEach((fitem) => {
                const mitem = subscope[fitem]

                if (mitem) {
                    mitem.optionInputs = {}
                    mitem.basePriceVariant = undefined
                    mitem.selectedSubVariant = undefined
                }
            })

            return { ...old }
        })


    }






    return { state, setChooseSubVariant, cleanOldSubMenuSelection, cleanOldSelection, setOptionState, setRootOptionState, everyVariantDone }

}


// utils


export const isEveryVariantDone = (substate: MenuItemInputState, menuItem: MenuItemData, variant: VariantData): boolean => {
    if (!variant) {
        return false
    }

    console.log("@echecking_variant", variant.id)

    const varState = substate[variant.id] || {}
    if (!varState) {
        console.log("@nofound/1", variant.id)
        console.log("@var/2")
        return false
    }

    if (variant.orderedChildrens.length) {
        if (!varState.selectedSubVariant) {
            console.log("@var/3")

            return false
        }



        const chidlVar = variant.childrens[varState.selectedSubVariant]
        if (!chidlVar) {
            console.log("@opt/4")
            return false
        }

        if (chidlVar.isLast) {
            console.log("@opt/5")
            return true
        }


        const nextVariant = menuItem.variants[varState.selectedSubVariant]
        if (!nextVariant) {
            console.log("@opt/6")
            console.log("@echecking_variant/3")
            return false
        }



        const nextdone = isEveryVariantDone(substate, menuItem, nextVariant)
        if (!nextdone) {
            return false
        }
    }



    return isEveryOptionDone(variant.options, varState.optionInputs || {})
}


export const isEveryOptionDone = (opts: Record<number, OptionData>, option_inputs: Record<number, OptionInputState>) => {
    return Object.values(opts).every((copt) => {

        console.log("@checking_option", copt.id)

        if (!copt.extrameta["is_required"]) {
            console.log("@echecking_variant/4")
            return true
        }

        const optionInput = option_inputs[copt.id]
        if (!optionInput) {
            console.log("@echecking_variant/5")
            return false
        }



        const min_permitted = copt.extrameta["min_permitted"]

        if (min_permitted) {

            if (countOfOptInputs(optionInput) < min_permitted) {
                console.log("@did_not_meet_min_count")
                return false
            }

        }



        return true
    })
}


export const countOfOptInputs = (optinput: OptionInputState) => {

    let count: number = 0


    Object.entries(optinput.modes).forEach(([key, mode]) => {
        const mkey = Number(key)

        if (mode === "checkbox") {
            if (optinput.selection[mkey]) {
                count = count + 1
            }
        } else if (mode === "counter") {
            count = count + Number(optinput.counterSeclection[mkey] || 0)
        } else if (mode === "radio") {
            if (optinput.radioSelection === mkey) {
                count = count + 1
            }
        }
    })



    console.log("@count_of_is", count)

    return count

}


export const sumOfOptInputs = (options: Record<number, OptionData>, optinput: OptionInputState) => {

    let sum: number = 0

    const priceObj = options[optinput.id]

    Object.entries(optinput.modes).forEach(([key, mode]) => {
        const mkey = Number(key)
        const subOptPrice = priceObj.subOptions[mkey]

        if (!subOptPrice || subOptPrice.price?.skip) {
            return
        }

        const oprice = Number(subOptPrice.price?.price || 0)
        const price = isNaN(oprice) ? 0 : oprice

        console.log("@processing/start", mkey, sum)
        console.log("@PRICE", price)


        switch (mode) {
            case "checkbox":
                if (optinput.selection[mkey]) {
                    sum = sum + price
                }
                break;
            case "counter":
                console.log("@IT_IS_A_COUNTER")
                const count = optinput.counterSeclection[mkey] || 0
                sum = sum + (price * count)

                break
            case "radio":
                if (optinput.radioSelection === mkey) {
                    sum = sum + price
                }
                break

        }

        console.log("@processing/end", mkey, sum)
    })




    console.log("@sum_is", optinput.id, optinput.modes, sum)

    return Number(sum)

}





export type InputHandle = ReturnType<typeof useInput>

export { useInput }