

interface PropsType {
    content: string
    onPick: (ans: boolean) => void
    className?: string
    typeIcon?: string
}


const PopUpQuestion = (props: PropsType) => {
    return (<>
        <div className={`bg-white mx-auto max-w-md rounded-md flex flex-col sup-card gap-4 p-4 ${props.className || ""}`}>
            <div className="flex gap-2">
                <div className="w-[24px] h-[24px] relative">
                    <svg>
                        <use href={`/sprites/sprites.svg#${props.typeIcon || "infoIcon"}`} />
                    </svg>
                </div>
                <h2>{props.content}</h2>
            </div>

            <div className="flex flex-col gap-2">
                <button
                    className="flex px-[8px] rounded-[4px] self-stretch justify-center items-center w-full min-h-[32px] text-white sup-btn bg-accents-secondary-accent"
                    onClick={() => {
                        props.onPick(true)
                    }}
                >
                    Yes
                </button>

                <button
                    className="flex px-[8px] rounded-[4px] self-stretch justify-center items-center w-full min-h-[32px] text-white sup-btn bg-accents-primary-button"
                    onClick={() => {
                        props.onPick(false)
                    }}
                >
                    No
                </button>
            </div>



        </div>
    </>)
}

export default PopUpQuestion;