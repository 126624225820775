import "../styles/globals.scss";
import "../styles/_colors.scss";
import ErrorBoundary from "./_error";
import React from "react";
import type { AppProps } from "next/app";
import { decrypt } from "../utils/commonFunction";
import WithMainModal from "../components/modal/WithMainModal";
import SuperCartProvider from "../containers/menu/cart/SuperCart/Provider";
import { ClientContext, useClientState } from "../state/hooks/useClientState";
import { APIProvider as GoogleAPIProvider } from "@vis.gl/react-google-maps";
import {
  AppContext,
  useRootAppState,
} from "../state/hooks/useRootAppState/useRootAppState";
import { useRouter } from "next/router";

declare global {
  interface Window {
    log_override_override?: boolean;
    __d__: any;
    __sup_curr_symbol__: any
    onRNToken: any
    forceAskRNToken: any
    onReactNativeMessage: any
  }
}

const maybeLog =
  (func: Function) =>
    (...args: any[]) => {
      if (typeof window !== "undefined" && window["log_override_override"]) {
        func(...args);
      }
    };

const OverRideLogger = () => {
  console.error = maybeLog(console.error);
  console.log = maybeLog(console.log);
  console.debug = maybeLog(console.debug);
  console.warn = maybeLog(console.warn);
};

function MyApp({ Component, pageProps }: AppProps) {
  // OverRideLogger()

  const cdata = useClientState();
  const rootApp = useRootAppState();
  const currencySymbol = cdata.siteJson?.siteAttributes?.currencySymbol;
  const router = useRouter();



  if (typeof window !== "undefined") {
    window["__d__"] = decrypt;
    window["__sup_curr_symbol__"] = currencySymbol || "R"

    window["onReactNativeMessage"] = (payload: Record<string, any> | string) => {
      console.log("onReactNativeMessage", payload)
      const processedPayload = (typeof payload === "string" ? JSON.parse(payload) : payload);

      if (processedPayload?.type === "REPLY_TOKEN") {
        console.log("REPLY_TOKEN", processedPayload)
        rootApp.auth.dtManager.processRNToken(processedPayload)
      } else if (processedPayload?.type === "USER_CLICKED_NOTIFICATION") {
        if (router.pathname.startsWith("/[host]/mobile")) {
          router.push(`/mobile/order-track/${processedPayload.orderUuid}`);
        } else {
          router.push(`/order-track/${processedPayload.orderUuid}`);
        }
      }
      window["forceAskRNToken"] = () => {
        rootApp.auth.dtManager.askTokenAnon()
      }
    }
  }


  console.log("@client_data", cdata);
  console.log("@root_app")

  return (
    <div>
      <ErrorBoundary>
        <ClientContext.Provider value={cdata}>
          <AppContext.Provider value={rootApp}>
            <SuperCartProvider>
              <WithMainModal>
                <div className="sup-container">

                  {rootApp.cartWatcher.isRecomputing ? (<>
                    <div className="flex justify-center items-center py-20 ">
                      Re computing cart, please wait
                    </div>
                  </>) : (<>
                    <Component {...pageProps} />
                  </>)}


                </div>
              </WithMainModal>
            </SuperCartProvider>
          </AppContext.Provider>
        </ClientContext.Provider>
      </ErrorBoundary>
    </div>
  );
}


interface WithGoogleMapsProps {
  children: React.ReactNode;
  apiKey?: string;
}

export const WithGoogleMaps = (props: WithGoogleMapsProps) => {

  return (<>
    {props.apiKey && <GoogleAPIProvider
      apiKey={props.apiKey || ""}
      libraries={["places", "maps", "geometry"]}
    >
      {props.children}
    </GoogleAPIProvider>}
    {!props.apiKey && <>{props.children}</>}
  </>)

}



export default MyApp;
