import { RendererOptions } from "../models/renderer";
import { useRoot } from "./useRoot";
import { useInput } from "./useInput";
import { useDerived } from "./derived/useDerived";
import { useEffect, useState } from "react";

export const useRenderer = (opts: RendererOptions) => {
    const root = useRoot(opts)
    const input = useInput(root, opts.initialInputs)
    const derived = useDerived(root, input, opts.count)
    const [notes, setNotes] = useState("");


    useEffect(() => {

        if (!derived.activeMenuItem?.activeVariantId) {
            return
        }

        if (!derived.activeMenuItem?.apiData) {
            return
        }


        const sInput = input.state[root.state.activeItemKey] || {}
        const currVarinatId = derived.activeMenuItem?.activeVariantId;
        const currVarinat = derived.activeMenuItem?.apiData?.variants[currVarinatId];

        if (!currVarinat) {
            return
        }

    
        if (currVarinat.orderedChildrens.length === 1) {
            const sub = sInput[currVarinatId] || {}
            const child = currVarinat.orderedChildrens[0]

            if (sub.selectedSubVariant) {
                return
            }

            input.setChooseSubVariant(root.state.activeItemKey, currVarinatId, child)
            root.gotoNextVariant(child)
        }    
    }, [root])



    useEffect(() => {

        console.log("@useEffect/vid")

        const activeScope = root.getActiveItem()

        activeScope?.variantsPaths?.forEach((vid) => {
            console.log("@useEffect/vid/each", vid)

            if (!activeScope.apiData) {
                return
            }
            
            if (activeScope.apiData.variants[vid]) {
                return
            }

            root.loadVariant(vid)
        })

        if (activeScope?.activeVariantId) {

            if (!activeScope.apiData) {
                return
            }
            
            if (activeScope.apiData.variants[activeScope?.activeVariantId]) {
                return
            }

            root.loadVariant(activeScope?.activeVariantId)
        }


    }, [root.state])


    return { root, input, derived, notes, setNotes, opts }
}


export type Handle = ReturnType<typeof useRenderer>