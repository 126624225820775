import axios, { Axios, AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import config from "../config/app";
import {
    decrypt,
    encrypt,
    removeUser,
    setUser,
} from "../utils/commonFunction";
import { getAuthToken } from "../state/hooks/useAuth/helper";

const { ECommerce, EncrytionDecryptionRequired } = config;


declare global {
    interface Window {
        redux?: any
        superpurger: any
    }
}



const getLoginUser = () => {
    //return reduxStore().auth.user;
};

const clearLoginDetails = async () => {
    console.log("@clearing_login_detail/start")
    // const persistor = reduxPersistor()
    // await persistor.purge()

    // const r = reduxHandler()

    //r.dispatch(authActions.authClear())
    // removeUser()
    // localStorage.removeItem("persist:root");
    // localStorage.clear(); // is this execessive
    // Cookies.remove("dxs")

    window.location.href = "/login";



    console.log("@clearing_login_detail/end")
};




export const autoDecrypt = (response: AxiosResponse) => {


    if (response && EncrytionDecryptionRequired) {
        const decyrptedPayload = decrypt(response?.data?.data);
        if (decyrptedPayload) {
            response.data = JSON.parse(decyrptedPayload);
        }
    }
    return response;
}


export const autoRefresh = async (error: AxiosError) => {
    const originalRequest = error.config as any;

    const userAuthData = getAuthToken();

    const refreshToken = userAuthData?.refreshToken;

    const url = (error.config?.url || "")

    if (url.includes("auth/")) {        
        return error
    }



    const respdata = error?.response?.data || {} as any
    const isETUSR404 = respdata["errorCode"] === "ETUSR404" || respdata["code"] === "ETUSR404"
    if (isETUSR404) {
        await clearLoginDetails()
        return error.response
    }


    if (originalRequest["_retry"]) {
        return
    }

    if (!refreshToken) {
        await clearLoginDetails()
        return error.response
    }

    if (error?.response?.status === 401) {
        originalRequest._retry = true;

        try {
            const refreshReq = await axios.post(`${ECommerce.CUSTOMER_URL}/auth/refreshToken`, {
                refreshToken,
            })

            if (refreshReq.status !== 200) {
                await clearLoginDetails()
                return
            }


            const data = refreshReq.data.data;

            const userAuth = {
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
            };

            setUser(userAuth);
            originalRequest.headers["x-access-token"] = data.accessToken
        } catch (error) {
            await clearLoginDetails()
            window.location.href = "/login";
        }

    }

    return error.response
}

export const autoEncryptAndInjectAuth = (configClient: InternalAxiosRequestConfig) => {
    // const loginUser = getLoginUser();
    const userAuthData = getAuthToken();
    const accessToken = userAuthData?.accessToken;

    console.log("@URL", configClient.url)


    configClient.headers = configClient.headers || {}

    configClient.headers["decryption_enabled"] = EncrytionDecryptionRequired;

    if (accessToken) {
        configClient.headers["x-access-token"] = accessToken;
    }

    if (!configClient.data) {
        return configClient
    }

    if (EncrytionDecryptionRequired && configClient.method === "post") {
        const encryptedPayload = encrypt(JSON.stringify(configClient.data));
        configClient.data = { data: encryptedPayload };
    }


    return configClient;
}


export const handleErr = (error: any) => {
    console.log("@handleErr", error)

    return Promise.reject(error)
}
