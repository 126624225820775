import { useContext, useState, createContext } from "react";
import type { ComponentType, } from "react"
import { useRouter } from "next/router";
import PopUp from "./PopUp";
import PopUpQuestion from "./PopUpQuestion";


export const ModalContext = createContext<ModalHandle | null>(null);

export interface modalState {
    props?: any
    compo?: ComponentType
    shown: boolean
}



export const useModal = () => useContext(ModalContext) as ModalHandle

export const useModalState = () => {
    const [state, setState] = useState<modalState>({ shown: false })

    const show = (compo: ComponentType, props: any) => {
        setState({
            shown: true,
            compo,
            props,
        })
    }

    const close = () => {
        setState({
            shown: false
        })
    }

    const showPopup = (content: string) => {
        show(PopUp as any, {
            content,
            close
        })
    }

    const showPopupQuestion = (content: string, queFunc: (ans: boolean) => void | Promise<void>) => {
        show(PopUpQuestion as any, {
            content,
            onPick: (ans: boolean) => {
                close()
                queFunc(ans)
            }

        })
    }


    return { _inner_state: state, show, close, showPopup, showPopupQuestion }
}



export type ModalHandle = ReturnType<typeof useModalState>

