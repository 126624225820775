import * as raw from "../models/raw"
import * as parsed from "../models/parsed"

export const menuItemFormatter = (serviceType: string, rawData: raw.DeployedMenuItemData, skiplist?: Record<number, boolean>): parsed.MenuItemData => {

    console.log("@menuItemFormatter/1")

    const menuItem = rawData.menuItemData;
    const options: Record<number, parsed.OptionData> = {}

    menuItem.options.forEach((opt) => {
        options[opt.id] = optionFormatter(serviceType, opt)
    })

    console.log("@menuItemFormatter/2")


    const variants: Record<string, parsed.VariantData> = {};

    rawData.rootVariants = filterWithSkipList(rawData.rootVariants, skiplist);
    rawData.rootVariants.forEach((variant) => {
        variants[variant.id] = variantFormatter(serviceType, variant)
    })

    console.log("@menuItemFormatter/3")


    const pdata = menuItem.extrameta["price_data"] || {}

    console.log("@menuItemFormatter/4")


    return {
        id: menuItem.id,
        description: menuItem.description,
        extrameta: menuItem.extrameta,
        itemType: menuItem.itemType,
        options: options,
        price: pdata[serviceType],
        orderedOptions: menuItem.options.map((v) => v.id),
        orderedVariants: rawData.rootVariants.map((v) => v.id),
        title: menuItem.title,
        variants: variants,
        imageURL: menuItem.imageURL,
    }
}

export const variantFormatter = (serviceType: string, variant: raw.DeployedMenuVariant): parsed.VariantData => {

    const childrens: Record<string, parsed.VariantChildData> = {}
    
    let baseVariantId: string | undefined = undefined
    let baseVariantAmount = 0

    console.log("@variantFormatter/1")
    
    variant.childrens.forEach((v) => {

        let cprices = v.childBasePrices
        if (!cprices) {
            // typo version backward compatibility
            cprices = (v as any).childBasePirces  || {}
        }


        const childv: parsed.VariantChildData = {
            title: v.title,
            description: v.description,
            hasOptions: v.hasOptions,
            id: v.id,
            isLast: v.isLast,
            price: v.price_data[serviceType],
            realMenuVariantId: v.realMenuVariantId,
            realVariantId: v.realVariantId,
            childBasePrice: cprices[serviceType] 
        }

        console.log("@variantFormatter/inside/2")

        const maybePrice = Number(childv.price?.price || 0) 

        if ( (!baseVariantId && maybePrice) || maybePrice < baseVariantAmount) {
            baseVariantId = childv.id
            baseVariantAmount = maybePrice
        }

        console.log("@variantFormatter/inside/3")


        childrens[v.id] = childv
    })

    console.log("@variantFormatter/4")

    const options: Record<number, parsed.OptionData> = {}

    variant.options.forEach((opt) => {

        console.log("@variantFormatter/inside/5")

        options[opt.id] = optionFormatter(serviceType, opt)
    })

    const pdata = variant.extrameta["price_data"] || {}

    console.log("@variantFormatter/6")


    return {
        id: variant.id,
        title: variant.title,
        childrens: childrens,
        description: variant.description,
        extrameta: variant.extrameta,
        price: pdata[serviceType],
        parentId: variant.parentId,
        orderedOptions: variant.options.map((o) => o.id),
        orderedChildrens: variant.childrens.map((v) => v.id),
        options: options,
        baseVariant: baseVariantId
    }

}

const optionFormatter = (serviceType: string, opt: raw.DeployedMenuOption) => {

    console.log("@optionFormatter/1")


    return {
        id: opt.id,
        code: opt.code,
        mainDesc: opt.mainDesc,
        optDesc: opt.optDesc,
        orderedSubOptions: opt.subOptions.map((v) => v.id),
        extrameta: opt.extrameta,


        subOptions: opt.subOptions.reduce((prev, curr) => {
            const p = prev as Record<number, parsed.SubOptionData>

            const pdata = curr.extrameta["price_data"] || {}

            console.log("@optionFormatter/inside/2")

            p[curr.id] = {
                id: curr.id,
                code: curr.code,
                mainDesc: curr.mainDesc,
                optDesc: curr.optDesc,
                source: curr.source as string,
                extrameta: curr.extrameta,
                price: pdata[serviceType],
                refMenuItemUuid: curr.extrameta["ref_uuid"],
            }

            return prev
        }, {})
    }

}

const filterWithSkipList = (variants: raw.DeployedMenuVariant[], skiplist?: Record<number, boolean>) => {

    console.log("@skipping_list")

    if (!variants) {
        return []
    }


    if (!skiplist) {
        return variants
    }

    const firstPass = variants.filter((variant) => {
        const realvid = variant.extrameta["variant_id"]
        if (!realvid) {
            return true
        }

        return !skiplist[realvid]
    })



    firstPass.forEach((v) => {
        v.childrens = v.childrens.filter((cv) => {
            const realvid = cv["realVariantId"]
            if (!realvid) {
                return true
            }

            return !skiplist[realvid]
        })
    })


    return firstPass

}