import { useEffect, useRef, useState } from "react";
import { RendererOptions } from "../models/renderer";
import { ItemKey, ItemPriceData, MenuItemPrice, OptionData, RootState, SubOptionData } from "../models";
import {
    calculateHash,
    extractVariantPath,
    menuItemFormatter,
    sleep,
    variantFormatter
} from "../utils";



export const useRoot = (opts: RendererOptions) => {
    const variantLoadInfo = useRef<Record<string, boolean>>({})
    const [state, setState] = useState<RootState>({
        mode: "main",
        menuItems: {
            [opts.itemUuid]: {
                itemKey: {
                    menuItemId: opts.itemUuid,
                },
                status: "loading",
                variantsPaths: extractVariantPath(opts.itemUuid, opts.initialInputs),
            }

        },
        activeItemKey: opts.itemUuid,
        rootMenuItem: opts.itemUuid,
        keysPath: [],
        subMenuItemPrices: opts.subMenuItemPrices || {},
    })

    const getActiveItem = () => {
        return state.menuItems[state.activeItemKey]
    }

    const loadRoot = async ()  => {
        console.log("@opts/1", opts)
        loadMenuItem({ menuItemId: opts.itemUuid })
    }

    const loadMenuItem = async (itemKey: ItemKey) => {
        console.log("@loadMenuItem/1", itemKey)


        const keyHash = calculateHash(itemKey)

        console.log("@keyHash", keyHash)


        let oldData = state.menuItems[keyHash]
        if (oldData && oldData.apiData) {
            console.assert(false, "@HAS_OLD_MENU_DATA_RETURNING", keyHash)
            return
        }
        console.log("@loadMenuItem/2")



        let menu_item_id = itemKey.menuItemId

        try {
            console.log("@loadMenuItem/3")

            const data = await opts.loader.LoadMenu(opts.menuUuid, opts.deployUuid, menu_item_id)

            if (!oldData) {
                console.log("@loadMenuItem/4")
                oldData = {
                    itemKey: itemKey,
                    variantsPaths: [],
                    status: "loaded",
                }
            }


            console.log("@loadMenuItem/5")

            let active_variant: string | undefined = undefined
            const paths: string[] = oldData.variantsPaths || []



            if (paths.length) {
                console.log("@loadMenuItem/6")
                active_variant = paths.at(-1)
            } else if (data.rootVariants.length) {
                console.log("@loadMenuItem/7")
                active_variant = data.rootVariants[0].id
                paths.push(active_variant)
            }

            console.log("@usingSkipList", oldData?.skipListData)

            const menuItemData = menuItemFormatter(opts.serviceType, data, oldData?.skipListData)
            console.log("@loadMenuItem/8")

            setState((old) => {
                return {
                    ...old,
                    activeItemKey: keyHash,
                    menuItems: {
                        ...old.menuItems,
                        [keyHash]: {
                            ...oldData,
                            apiData: menuItemData,
                            itemKey: itemKey,
                            message: undefined,
                            status: "loaded",
                            variantsPaths: paths,
                            activeVariantId: active_variant
                        }
                    }

                }
            })


        } catch (error) {
            console.log("@loadMenuItem/10", error)

            console.debug(error)



            setState((old) => {

                return {
                    ...old,
                    activeItemKey: keyHash,
                    menuItems: {
                        [keyHash]: {
                            itemKey: itemKey,
                            status: "error",
                            variantsPaths: [],
                            message: "Some error occured!",
                        }
                    }
                }
            })
        }

    }

    const dryLoadSubMenu = (parentScope: string, menuItemId: string, optId: number, subOptId: number) => {
        console.log("@dryLoadSubMenu")


        const currData = state.menuItems[parentScope]

        console.assert(currData !== undefined, "dryLoadSubMenu/currData should not be empty")


        const itemKey = {
            menuItemId: menuItemId,
            parentKey: parentScope,
            optionId: optId,
            subOptionId: subOptId,
            parentMenuItemId: currData?.itemKey?.menuItemId,
            parentOptionId: currData?.itemKey?.optionId,
            parentSubOptionId: currData?.itemKey?.subOptionId
        }

        const keyHash = calculateHash(itemKey)

        if (state.menuItems[keyHash]) {
            return
        }

        setState((old) => ({
            ...old,
            menuItems: {
                [keyHash]: {
                    itemKey: itemKey,
                    status: "loading",
                    variantsPaths: [],
                }

            }

        }))

        loadMenuItem(itemKey)

    }


    const loadVariant = async (vid: string) => {

        console.log("loadVariant", vid)


        const ascope = state.menuItems[state.activeItemKey]
        if (ascope.apiData?.variants[vid]) {
            return
        }

        if (variantLoadInfo.current[vid]) {
            console.log("loadVariant/skip", vid)
            return
        }

        variantLoadInfo.current[vid] = true

        try {



            const data = await opts.loader.LoadVariant(opts.menuUuid, opts.deployUuid, ascope.apiData?.id as string, vid)

            variantLoadInfo.current[vid] = false

            // goNext

            const vdata = variantFormatter(opts.serviceType, data)

            setState((old) => {

                const oldData = old.menuItems[state.activeItemKey]
                if (!oldData) {
                    return old
                }

                const apiData = oldData.apiData;
                if (!apiData) {
                    return old
                }



                return {
                    ...old,
                    menuItems: {
                        ...old.menuItems,
                        [state.activeItemKey]: {
                            ...oldData,
                            apiData: {
                                ...apiData,
                                orderedVariants: [...apiData.orderedVariants, vdata.id],
                                variants: { ...apiData.variants, [vdata.id]: vdata }
                            }
                        }
                    }
                }

            })

        } catch (error) {
            console.log("@error", error)
        }

    }

    const goBackFromSubMenuItem = () => {

        console.log("@GO_BACK")

        const sub_keys = state.keysPath

        if (!sub_keys || sub_keys.length === 0) {
            console.log("@SKIP")
            return
        }

        const parent = sub_keys.pop()
        if (!parent) {
            console.log("@ORPHAN :(")
            return
        }


        const currData = state.menuItems[state.activeItemKey]
        const parentKey = currData.itemKey.parentKey as string

        const firstVariant = currData.variantsPaths[0];

        const vpaths = firstVariant ? [firstVariant] : []


        setState((old) => {
            return {
                ...old,

                menuItems: {
                    ...old.menuItems,
                    [state.activeItemKey]: {
                        ...currData, variantsPaths: vpaths, activeVariantId: firstVariant
                    },
                },
                keysPath: sub_keys,
                activeItemKey: parentKey,
                mode: sub_keys.length ? "nested" : "main"
            }
        })
    }



    const saveSubMenuItemAndGoBack = (priceData: ItemPriceData) => {
        console.log("@GO_SAVE_AND_BACK")

        const sub_keys = state.keysPath

        console.log("@SUBKEYS", sub_keys)


        if (!sub_keys || sub_keys.length === 0) {
            return
        }

        const parent = sub_keys.pop()
        if (!parent) {
            return
        }

        const currData = state.menuItems[state.activeItemKey]
        if (!currData || !currData.itemKey.optionId || !currData.itemKey.subOptionId || !currData.parentOptionCachedData) {
            console.assert(false, "curr data and ids should not be empty", currData)
            return
        }


        const currentKeyHash = state.activeItemKey
        const parentKey = currData.itemKey.parentKey

        const subOption = currData.parentOptionCachedData.subOptions[currData.itemKey.subOptionId]



        setState((old) => {

            return {
                ...old,
                activeItemKey: parentKey as string,
                subMenuItemPrices: { ...old.subMenuItemPrices, [currentKeyHash]: subItemPriceObject(subOption, priceData, currData.itemKey) },
                menuItems: {
                    ...old.menuItems,
                },
                keysPath: sub_keys,
                mode: sub_keys.length ? "nested" : "main"
            }
        })
    }

    const gotoSubMenuItemDepth = (menuItemId: string, option: OptionData, subOptionId: number) => {
        const skiplist = option.extrameta["variant_skip_list"]

        const optionId = option.id


        // menuItemId: string, optionId: number, subOptionId: number, skiplist?: Record<number, boolean>
        console.log("@GOING_TO_DEPTH", menuItemId, optionId, subOptionId)

        const currData = state.menuItems[state.activeItemKey]
        if (!currData) {
            return
        }

        const subOpt = option.subOptions[subOptionId]


        const newKey: ItemKey = {
            displayName: subOpt.mainDesc,
            menuItemId: menuItemId,
            optionId: optionId,
            subOptionId: subOptionId,
            parentKey: state.activeItemKey,
            parentMenuItemId: currData.itemKey.menuItemId,
            parentOptionId: currData.itemKey.optionId,
            parentSubOptionId: currData.itemKey.subOptionId
        }

        const newkeyHash = calculateHash(newKey)


        const newScopeOldData = state.menuItems[newkeyHash] || {
            itemKey: newKey,
            variantsPaths: extractVariantPath(newkeyHash, opts.initialInputs),
            status: "loading",
            parentOptionCachedData: option,
        }

        newScopeOldData.skipListData = skiplist

        const keyPaths = state.keysPath || []

        keyPaths.push(newKey)


        setState((old) => {

            return {
                ...old,
                menuItems: {
                    ...old.menuItems,
                    [newkeyHash]: newScopeOldData,
                },

                mode: "nested",
                keysPath: keyPaths,
                activeItemKey: newkeyHash
            }

        })
    }


    const gotoNextVariant = (nvid: string) => {

        const itemState = getActiveItem()


        if (itemState.variantsPaths.includes(nvid)) {
            return
        }

        const nextPaths = [...itemState.variantsPaths, nvid]

        console.log("@HERE_HERE")


        setState((old) => {
            return { ...old, menuItems: { ...old.menuItems, [state.activeItemKey]: { ...itemState, activeVariantId: nvid, variantsPaths: nextPaths } } }
        })

        loadVariant(nvid)

    }


    const reGotoNextVariant = (atvid: string, nvid: string) => {
        const currscope = state.activeItemKey

        const sstate = state.menuItems[currscope]
        if (!sstate) {
            return
        }

        const path = [...sstate.variantsPaths]
        const atIndex = path.findIndex((v) => v == atvid)
        if (atIndex === -1) {
            return
        }

        path.splice((atIndex + 1))
        path.push(nvid)

        console.log("@HERE", atIndex)


        setState((old) => {
            return {
                ...old,
                menuItems: {
                    ...old.menuItems,
                    [currscope]: {
                        ...sstate, variantsPaths: path, activeVariantId: nvid
                    }
                }
            }
        })


        loadVariant(nvid)
    }




    return {
        state,
        loadMenuItem,
        dryLoadSubMenu,
        loadRoot,
        loadVariant,
        getActiveItem,
        gotoNextVariant,
        reGotoNextVariant,
        goBackFromSubMenuItem,
        saveSubMenuItemAndGoBack,
        gotoSubMenuItemDepth
    }
}

export const subItemPriceObject = (subOpt: SubOptionData, priceData: ItemPriceData, key: ItemKey) => {

    const isZeroPrice = (subOpt?.extrameta || {})["zero_price"] || false

    let nextprice = (
        Number(priceData.baseItemAmount || 0) +
        Number(priceData.itemOptionsTotalAmount || 0) +
        Number(priceData.variantSelectedAmount || 0) +
        Number(priceData.variantOptionsTotalAmount || 0)
    )
    if (isZeroPrice) {
        nextprice = nextprice - priceData.variantBaseAmount
    } else {
        const parentSubOptionPrice = subOpt.price?.price || 0
        if (parentSubOptionPrice) {
            nextprice = ((priceData.itemOptionsTotalAmount + priceData.variantSelectedAmount + priceData.variantOptionsTotalAmount) - priceData.variantBaseAmount)
        }
    }

    return {
        itemData: priceData,
        total: nextprice,
        isZeroPrice,
        key: key,
    }

}




export type RootHandle = ReturnType<typeof useRoot>