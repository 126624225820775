import { useEffect, useState } from "react"
import { CartHandle } from "../../../containers/menu/cart/SuperCart/cart"
import { ActiveStoreHandle } from "../useActiveStore/useActiveStore"
import { reCalculateItemPrices } from "../../../containers/menu/SuperRenderer/sub/utils/calculator"
import { loader } from "../../../containers/menu/menu-item/sub/loader"
import { sleep } from "../../../utils/commonFunction"



interface Options {
    storeHandle: ActiveStoreHandle
    cartHandle: CartHandle
}

export const useCartWatcher = (opts: Options) => {
    const [currentServiceType, setCurrentServiceType] = useState(opts.storeHandle.state.serviceType)
    const [isRecomputing, setIsRecomputing] = useState(false)


    useEffect(() => {

        const recompute = async (ns: string) => {

            try {



                setCurrentServiceType(ns as any)
                setIsRecomputing(true)

                // await sleep(3000)

    
                for (const cartItem in opts.cartHandle.state.items) {
        
                    const item = opts.cartHandle.state.items[cartItem]
        
                    const newCartItem = await reCalculateItemPrices({
                        deployementId: opts.cartHandle.state.deploymentId as string,
                        input: item.inputs,
                        loader: loader,
                        menuId: "",
                        menuItemId: item.mainMenuItemUuid,
                        serviceType: ns,
                        currentCartItem: item,
                    })
        
                    console.log(`@REMCOMPUTE/${cartItem}`, (item.extraPrice + item.itemPrice), (newCartItem.extraPrice + newCartItem.itemPrice))
        
                    opts.cartHandle.setItem(newCartItem)
                }
    
                setIsRecomputing(false)
                
            } catch (error) {
                console.log("@error_recomputing_cart")
                console.debug(error)
                opts.cartHandle.clearItems()
                setCurrentServiceType(undefined)
                setIsRecomputing(false)
            }


        }

        const nextServiceType = opts.storeHandle.state.serviceType;

        if (currentServiceType === nextServiceType) {
            return
        }

        if (!currentServiceType && nextServiceType) {
            setCurrentServiceType(nextServiceType)
            return
        }

        if (!nextServiceType) {
            opts.cartHandle.clearItems()
            setCurrentServiceType(undefined)
            return
        }


        recompute(nextServiceType)



    }, [opts.storeHandle.state])

    return {isRecomputing}
}