import { Handle } from "../hooks";
import { InputState, MenuItemData, MenuItemInputState, MenuItemPrice, MenuItemState, OptionData, VariantInputState } from "../models";
import { OptionCache, VariantCache, CartItem } from "../models/cart";
import { calculateHash } from "./utils";


export interface InputCartOptions {
  cartId: string
  mainMenuItemId: string
  itemNotes: string
  extraAmount: number
  itemAmount: number
  count: number
  input: InputState
  subMenuItemPrices: Record<string, MenuItemPrice>
  subMenuItems: Record<string, MenuItemState>
}

export const inputToCart = (cartId: string, menuItemId: string, handle: Handle) => {
  const total = handle.derived.price.getTotal();


  return inputToCartWithOptions({
    cartId: cartId,
    count: handle.derived.price.state.count,
    extraAmount: total.extraAmount,
    itemAmount: total.itemAmount,
    input: handle.input.state,
    itemNotes: handle.notes,
    mainMenuItemId: menuItemId,
    subMenuItemPrices: handle.root.state.subMenuItemPrices,
    subMenuItems: handle.root.state.menuItems
  })

}



export const inputToCartWithOptions = (opts: InputCartOptions) => {

  const itemScopedData = opts.subMenuItems[opts.mainMenuItemId]

  const menuItemData = itemScopedData.apiData

  if (!menuItemData) {
    throw new Error(`Main menu item cannot be empty ${opts.mainMenuItemId}`)
  }


  const data: CartItem = {
    cache: {
      optionsName: [],
      variantsName: [],
    },
    cartItemId: opts.cartId,
    count: opts.count,
    inputs: opts.input,
    itemName: menuItemData?.title as string,
    mainMenuItemUuid: opts.mainMenuItemId,
    extraPrice: opts.extraAmount,
    itemPrice: opts.itemAmount,
    subMenuItemPrices: opts.subMenuItemPrices,
    notes: opts.itemNotes,
  };





  const vnames = extraVariantInfo(opts, opts.input[opts.mainMenuItemId] || {}, menuItemData, itemScopedData.variantsPaths)
  data.cache.variantsName.push(...vnames)


  const menuInputs = opts.input[opts.mainMenuItemId] || {};



    const vinput = menuInputs["root"]
    if (vinput) {
      const optnames = extractSubOptions(opts, vinput, menuItemData, "root")
      data.cache.optionsName.push(...optnames)
    }



  return data
}

const extractSubOptions = (processOpts: InputCartOptions, vInputs: VariantInputState, menuItem: MenuItemData, vkey: string) => {


  console.log("@extractSubTypesForPreview/0", vInputs, vkey)

  const optionNames: OptionCache[] = []

  Object.values(vInputs.optionInputs || {}).forEach((opt) => {

    console.log("@extractSubTypesForPreview/1", opt)



    const optData = vkey === "root" ? menuItem.options[opt.id] : (menuItem.variants[vkey] || {}).options[opt.id]
    if (!optData) {
      console.log("@extractSubTypesForPreview/2")
      return;
    }

    const sdata: OptionCache = {
      title: optData.mainDesc,
      selection: "",
    };

    let optPrice = 0


    Object.entries(opt.modes).forEach(([subkey, subMode]) => {
      const subOptId = Number(subkey);

      console.log("@processing/opt", subkey, subMode)

      const subopt = optData.subOptions[subOptId];
      if (!subopt) {
        console.log("@skipping", subOptId)
        return;
      }

      let price = subopt.price?.price || 0

      if (subMode === "checkbox") {
        sdata.selection = `${sdata.selection}  ${subopt.mainDesc}`;
      } else if (subMode === "counter") {
        const counter = opt.counterSeclection[subOptId];
        if (counter) {
          sdata.selection = `${sdata.selection}  ${subopt.mainDesc} x ${counter}`;
          price = price * counter;
        }

      } else if (subMode === "radio") {
        if (subOptId === opt.radioSelection) {
          sdata.selection = `${sdata.selection}  ${subopt.mainDesc}`;
        }
      }

      optPrice = optPrice + price;


      if (subopt.refMenuItemUuid && sdata.selection) {
        console.log("@processing/submenu/1", subopt.refMenuItemUuid)

        const subitem = Object.values(processOpts.subMenuItems).find((subMenuItem) => {
          if (subMenuItem.itemKey.menuItemId !== subopt.refMenuItemUuid) {
            return false
          }

          if (optData.id !== subMenuItem.itemKey.optionId) {
            return false
          }

          if (subopt.id !== subMenuItem.itemKey.subOptionId) {
            return false
          }

          // fixme => also check parent stuff

          return true


        })

        console.log("@processing/submenu/2", subitem)


        if (subitem?.apiData) {
          console.log("@processing/submenu/3")


          const newKey = calculateHash(subitem.itemKey)
          const input = processOpts.input[newKey]
          const currMenuItemData = subitem.apiData



          sdata.subItem = {
            optionsName: [],
            variantsName: extraVariantInfo(processOpts, input, currMenuItemData, subitem?.variantsPaths || []),            
          }

          const vinput = input["root"]
          if (vinput) {
            const optnames = extractSubOptions(processOpts, vinput, currMenuItemData, vkey)
            sdata.subItem.optionsName.push(...optnames)
          }

        }
        console.log("@processing/submenu/4")

      }


    });

    if (sdata.selection) {
      sdata.price = optPrice ? `${optPrice}` : undefined;
      optionNames.push(sdata)
    }

    console.log("@processing/submenu/5")


  });

  return optionNames
}


const extraVariantInfo = (processOpts: InputCartOptions, menuInputs: MenuItemInputState, menuItem: MenuItemData, variant_paths: string[]) => {

  const variantNames: VariantCache[] = []

  const variants = menuItem.variants || {}

  console.log("@variant_paths", variant_paths)


  variant_paths.forEach((vid: string, index: number) => {
    if (index === variant_paths.length - 1) {
      return;
    }

    const variant = variants[vid]
    if (!variant) {
      return
    }



    const currInput = menuInputs[vid] || {};
    const selctedVariant = variants[currInput.selectedSubVariant as string]
    console.log("@selctedVariant", selctedVariant);
    if (!selctedVariant) {
      console.log("@selctedVariant/nofound", selctedVariant);
      console.log("@variants", variants);
    }


    const desc: string =variant.extrameta["select_info"] || variant.title ;
    const selection = selctedVariant?.title || "";

    const selectedVPrice = selctedVariant?.price?.price || 0;

    console.log("@FINAL", selectedVPrice);

    const vcache: VariantCache = {
      title: desc,
      selection: selection,
      price: selectedVPrice  ? `${selectedVPrice}` : undefined,
      options: []
    }

    if (currInput.selectedSubVariant) {
      const selectedInput = menuInputs[currInput.selectedSubVariant] || {};
      vcache.options = extractSubOptions(processOpts,selectedInput, menuItem, currInput.selectedSubVariant)
    }


    console.log("vcache.options", vcache.options)


    variantNames.push(vcache);





  });

  return variantNames
}

