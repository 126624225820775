import { useEffect, useState } from "react"
import { InputHandle, countOfOptInputs } from "../useInput"
import { RootHandle } from "../useRoot"
import { OptionData, VariantInputState } from "../../models"



export type OptionMaxIndex = Record<string, Record<number, boolean>>


// This hook state is used for graying (making input disabled ) when max allowed is reached.
// for example if modifier group has max of two chips pickable after user picks two other 
// inputs should go to disabled state

const useMaxOptionCount = (root: RootHandle, input: InputHandle) => {
    const [state, setState] = useState<OptionMaxIndex>({})


    useEffect(() => {

        const scopedData = root.state.menuItems[root.state.activeItemKey]
        const scoppedInput = input.state[root.state.activeItemKey] || {}


        if (!scopedData || !scopedData.apiData) {
            return
        }

        const newIndex: OptionMaxIndex = {}
        const rootInputIndex = scoppedInput["root"]
        if (rootInputIndex) {
            newIndex["root"] = optionToMaxCountMap(rootInputIndex, scopedData.apiData.orderedOptions, scopedData.apiData.options || {});
        }


        scopedData.apiData.orderedVariants.forEach((vkey) => {
            const variant = scopedData.apiData?.variants[vkey]


            const vinput = scoppedInput[vkey]
            if (!vinput) {
                return
            }
            newIndex[vkey] = optionToMaxCountMap(vinput, variant?.orderedOptions || [], variant?.options || {});
        })


        setState(newIndex)

    }, [root.state, input.state])

    return state
}



export const optionToMaxCountMap = (inputIndex: VariantInputState, optionList: number[], options: Record<number, OptionData>) => {

    const maxindex: Record<number, boolean> = {}


    optionList.map((oid) => {
        const option = options[oid]

        const optInputs = inputIndex.optionInputs[option.id]
        if (!optInputs) {
            return false
        }

        const max_permitted = Number(option.extrameta["max_permitted"] || 0)
        if (max_permitted) {

            const count = countOfOptInputs(optInputs)
            console.log("@________count", count, max_permitted)
            if (count === max_permitted) {
                return true
            }
        }

        const grouptype = option?.extrameta?.groupType;



        if ( grouptype === "SWAP") {
           
            const count = countOfOptInputs(optInputs)
            if (count >= 1) {
                return true
            }
        }


        return false
    }).forEach((value, index) => {
        const optId = optionList[index]
        maxindex[optId] = value
    })

    return maxindex
}


export { useMaxOptionCount }