import type { NextApiRequest, NextApiResponse } from "next";


export const logoCacheDataIndex: Record<string, string> = {};
export const logoCacheFreshNessIndex: Record<string, Date> = {} // new Date()
export const logoHashIndex: Record< string, string> = {};

export const stylerCssCacheDataIndex: Record<string, string> = {};
export const stylerCssCacheFreshNessIndex: Record<string, Date> = {} // new Date()
export const stylerCssHashIndex: Record< string, string> = {};

export const stylerJsCacheDataIndex: Record<string, string> = {};
export const stylerJsCacheFreshNessIndex: Record<string, Date> = {} // new Date()
export const stylerJsHashIndex: Record< string, string> = {};

export const domainExtractor = (req: NextApiRequest) => {
    const referer = req.headers["referer"] as string || req.headers["origin"] as string || req.headers.host as string
      let clientSlug = referer.replace("http://", "").replace("https://", "").split(":")[0]
      if (clientSlug.endsWith("/")) {
        clientSlug = clientSlug.slice(0, -1)
      }

      if (clientSlug.includes("/")) {
        clientSlug = clientSlug.split("/")[0]
      }

  
      return clientSlug
  }
  


export default function handler(req: NextApiRequest, res: NextApiResponse) {
    if (req.method === "DELETE" || req.query.nuke_em) {
        let { clientSlug } = req.body as Record<string, string>;

        if (!clientSlug) {
            clientSlug = domainExtractor(req);
        }

        delete logoCacheDataIndex[clientSlug];
        delete logoCacheFreshNessIndex[clientSlug];
        delete logoHashIndex[clientSlug];

        delete stylerCssCacheDataIndex[clientSlug];
        delete stylerCssCacheFreshNessIndex[clientSlug];
        delete stylerCssHashIndex[clientSlug];

        delete stylerJsCacheDataIndex[clientSlug];
        delete stylerJsCacheFreshNessIndex[clientSlug];
        delete stylerJsHashIndex[clientSlug];

        res.status(200).write("ok");
        res.end();
        return;
    }

    if (req.method === "GET") {
        // list all cache data
        const data = JSON.stringify({
            logoCacheDataIndex,
            logoCacheFreshNessIndex,
            logoHashIndex,
            stylerCssCacheDataIndex,
            stylerCssCacheFreshNessIndex,
            stylerCssHashIndex,
            stylerJsCacheDataIndex,
            stylerJsCacheFreshNessIndex,
            stylerJsHashIndex,
        });
        console.log("@cache/data", data)
        console.log("@cache/data/length", data.length)
        res.setHeader('Content-Type', 'application/json');
        res.status(200).write(data);
        res.end();
        return;
    }



}
