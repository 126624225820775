import { useEffect, useState } from "react"
import { getAuthToken, setAuthToken } from "./helper"
import { setCookie } from "../../../utils/commonFunction"
import { LoginResponse } from "../../../api/user"
import { useRouter } from "next/router"
import useDeviceTokenManager from "./useDeviceTokenManager"


export interface AuthState {
    loggedIn: boolean
    userDetail?: UserDetail
    isFirstSignUp?: boolean
    justChangedPassword?: boolean
}


export interface UserDetail {
    firstName: string
    lastName: string
    email: string
    uuid: string
}

declare global {
    interface Window {
        ReactNativeWebView?: any
    }
}


export const useAuthState = () => {
    const [state, setState] = useState<AuthState>({ loggedIn: false })
    const router = useRouter()
    const dtManager = useDeviceTokenManager(state.loggedIn)

    useEffect(() => {
        if (!window || !localStorage) {
            return
        }

        if (!state.loggedIn) {

            const tok = getAuthToken()
            if (!tok) {
                return
            }

            const authPayload = localStorage.getItem("authPayload")
            if (authPayload) {
                const authPayloadJson = JSON.parse(authPayload)

                console.log("@authPayloadJson", authPayloadJson)

                setState({ loggedIn: true, userDetail: authPayloadJson.userDetail })
            }
        }


    }, [])

    const applyLoginFinish = (payload: LoginResponse) => {

        setAuthToken({
            accessToken: payload.accessToken as string,
            refreshToken: payload.refreshToken as string
        })

        if (router.pathname.startsWith("/[host]/mobile")) {
            setCookie("mapp", "yes", 300);
        }

        setCookie("dxs", "logged", 300);


        setAuthState({
            loggedIn: true,
            userDetail: {
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                uuid: payload.uuid
            },
            isFirstSignUp: false,
            justChangedPassword: false
        })

        dtManager.askTokenOnLogin(payload)

    }




    const setAuthState = (authPayload: AuthState) => {
        setState(authPayload)
        localStorage.setItem("authPayload", JSON.stringify(authPayload))
    }

    const clearLoginState = () => {
        setAuthState({ loggedIn: false })
        localStorage.removeItem("authPayload")
        localStorage.removeItem("__auth_token__")
        localStorage.removeItem("__pre_authed_token__")
        // dxs
        document.cookie = ""
        dtManager.clearOnLogout()

    }

    const clearSignupFlag = () => {
        setState({ ...state, isFirstSignUp: false })
    }

    return {
        state,
        clearLoginState,
        clearSignupFlag,
        applyLoginFinish,
        dtManager,
    }
}


export type Authhandle = ReturnType<typeof useAuthState>




