import { createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from "../useAuth/useAuth";
import { useCartState } from "../../../containers/menu/cart/SuperCart/cart";
import useActiveStore, { ServiceType } from "../useActiveStore/useActiveStore";
import { useRouter } from "next/router";
import * as api from "../../../api";
import { useCartWatcher } from "./useCartWatcher";
import config from "../../../config/app";

const DEBUG_KEY = "whereIsBogeyman"

declare var globalThis: {
  [DEBUG_KEY]?: Function;
};


export const getMenuPageURL = (store: any) => {
  const modifiedStoreName = store?.name.toLowerCase().replace(/\s+/g, "-");
  return `/menu/${modifiedStoreName}/${store?.uuid || ""}`;
};

const debugKey = "fallen_angel_demon"

export const useRootAppState = () => {
  const auth = useAuthState();
  const cart = useCartState();
  const activeStore = useActiveStore();
  const router = useRouter();

  const [hookHandles, setHookHandles] = useState<Record<string, any>>({});
  const [debug, setDebug] = useState(false);

  const cartWatcher = useCartWatcher({
    cartHandle: cart,
    storeHandle: activeStore,
  })


  // debug thing

  useEffect(() => {
    if (typeof window !== "undefined") {
      const debugStr = localStorage.getItem(debugKey)
      if (config.showDebugUI && debugStr === null) {
        setDebug(config.showDebugUI)
      } else {
        setDebug(debugStr === "true")
      }

    }

  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const debugString = localStorage.getItem(debugKey)
      console.log("@@save_debug1", debugString, debug)

      if ((!debugString && debug) || debugString) {
        console.log("@@save_debug2", debugString, debug)

        localStorage.setItem(debugKey, String(debug))
      }



    }

  }, [debug])

  // dynamic hook register


  const registerHook = (name: string, hook: any) => {
    setHookHandles((old) => ({ ...old, [name]: hook }));
  };

  const unRegisterHook = (name: string) => {
    setHookHandles((old) => {
      const newHandles = { ...old };
      delete newHandles[name];
      return newHandles;
    });
  };

  
  // store

  const selectStore = (store: any, serviceType: ServiceType) => {
    cart.clearItems();
    activeStore.setStore(store);
    activeStore.setServiceType(serviceType);

    (async () => {
      const resp = await api.getStoreById(store.id);
      if (resp?.status !== 200) {
        return;
      }
      activeStore.setStoreWithServiceType(resp?.data, serviceType);
    })();


    const menuPageURL = getMenuPageURL(store)

    if (router.pathname.startsWith("/[host]/mobile")) {
      router.push(`/mobile${menuPageURL}`);
    } else {
      router.push(menuPageURL);
    }


    
  };

  const selectStoreWithFullInfo = (store: any, serviceType: any) => {
    cart.clearItems();
    activeStore.setStoreWithServiceType(store, serviceType);
    router.push(getMenuPageURL(store));
  };



  const h = {
    debug,
    cartWatcher,
    hookHandles,
    auth,
    cart,
    activeStore,
    registerHook,
    unRegisterHook,
    selectStoreWithFullInfo,
    selectStore,
    toggleDebug: () => {
      console.log("@root", h)
      setDebug((old) => !old);
    }
  };


  useEffect(() => {
    if (globalThis !== undefined) {
      globalThis[DEBUG_KEY] = h.toggleDebug
    }

    return () => {
      delete globalThis[DEBUG_KEY]
    };
  }, []);





  return h
};

export type AppHandler = ReturnType<typeof useRootAppState>;

export const AppContext = createContext<AppHandler | null>(null);

export const useRootApp = () => useContext(AppContext) as AppHandler;
